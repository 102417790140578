import { useState } from 'react';
import useAuth from './useAuth';

export default function useNavigation() {
  const [isShowLogout, setIsShowLogout] = useState(false);

  const {
    user,
    logout
  } = useAuth();

  const canAccessClientes = () => {
    return user?.is_administrador || user?.is_contador;
  };

  const canAccessContadores = () => {
    return user?.is_administrador;
  };

  const onShowLogout = () => {
    setIsShowLogout(true);
  };

  const onCancelLogout = () => {
    setIsShowLogout(false);
  };

  const onLogout = () => {
    logout();
  };

  return {
    isShowLogout,
    canAccessClientes,
    canAccessContadores,
    onShowLogout,
    onCancelLogout,
    onLogout
  };
}
