export default function bytesToSize(bytes, fractionDigits = 2) {
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];

  if (bytes === 0) {
    return '0 Bytes';
  }

  const sizeIndex = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);

  if (sizeIndex === 0) {
    return `${bytes} ${sizes[sizeIndex]}`;
  }

  return `${(bytes / (1024 ** sizeIndex)).toFixed(fractionDigits)} ${sizes[sizeIndex]}`;
}
