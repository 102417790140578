import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import useAuth from '../hooks/useAuth';
import * as actions from '../store/global/authSlice';

const UserSchema = Yup.object().shape({
  full_name: Yup.string().required('El campo "Nombre completo" es obligatorio.'),
  username: Yup.string().required('El campo "Usuario" es obligatorio.'),
  password: Yup.string().when('id', {
    is: (value) => !value,
    then: Yup.string().required('El campo "Contraseña" es obligatorio.')
  }),
  password_confirmation: Yup.string().when('password', {
    is: (value) => !!value,
    then: Yup.string().required('Vuelva a escribir su contraseña.')
      .oneOf([Yup.ref('password'), null], 'Las contraseñas no coinciden.')
  })
});

export default function useAdministracion() {
  const [isShowUpdatedMessage, setIsShowUpdatedMessage] = useState(false);
  const dispatch = useDispatch();

  const {
    user,
    isUpdating,
    isUpdated
  } = useAuth();

  const formik = useFormik({
    initialValues: {
      full_name: '',
      username: '',
      password: '',
      password_confirmation: ''
    },
    validationSchema: UserSchema,
    onSubmit: (values) => dispatch(actions.updateProfile(values))
  });

  useEffect(() => {
    setUser();

    return () => {
      leaveProfile();
    };
  }, []);

  useEffect(() => {
    updatedProfile();
  }, [isUpdated]);

  const isCliente = () => {
    return user?.is_cliente;
  };

  const setUser = () => {
    formik.setValues({
      ...user,
      password: '',
      password_confirmation: ''
    });
  };

  const updatedProfile = async () => {
    setIsShowUpdatedMessage(isUpdated);

    if (isUpdated) {
      await formik.setFieldValue('password', '');
      await formik.setFieldValue('password_confirmation', '');
      formik.setErrors({});
    }
  };

  const leaveProfile = () => {
    dispatch(actions.leaveProfile());
  };

  const onHideUpdatedMessage = () => {
    setIsShowUpdatedMessage(false);
  };

  return {
    formik,
    isUpdating,
    isShowUpdatedMessage,
    isCliente,
    onHideUpdatedMessage
  };
}
