import { useEffect, useReducer, useContext } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import FilesContext from '../contexts/FilesContext';
import useAuth from './useAuth';
import { initialState, reducer } from '../store/local/reducers/files-anios';
import * as actions from '../store/local/actions/files-anios';

export default function useFilesAnios() {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { file, isUploadedSuccessfully } = useContext(FilesContext);
  const location = useLocation();
  const { userId = 0 } = useParams();
  const { user } = useAuth();

  useEffect(() => {
    getYears();
  }, [userId]);

  useEffect(() => {
    if (isUploadedSuccessfully) {
      addToFilesCount();
    }
  }, [isUploadedSuccessfully]);

  const getYears = () => {
    actions.getYears(userId)(dispatch);
  };

  const getLinkPath = (id) => {
    return user?.is_cliente ? `anios/${id}/meses` : `${id}/meses`;
  };

  const getLinkState = (year) => {
    const cliente = location.state?.menu?.[0];
    const path = user?.is_cliente ? `anios/${year.id}/meses` : `${cliente?.id}/anios/${year.id}/meses`;

    return {
      ...location.state,
      menu: [
        cliente,
        {
          id: year.id,
          title: year.year,
          path
        }
      ]
    };
  };

  const addToFilesCount = () => {
    const yearId = +file.year_id;

    if (+file.user_id !== +userId) {
      return;
    }

    actions.addToFilesCount(yearId, 1)(dispatch);
  };

  return {
    years: state.years,
    isLoading: state.isLoading,
    getLinkPath,
    getLinkState
  };
}
