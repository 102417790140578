export const GET_CLIENTES_INIT = 'GET_CLIENTES_INIT';
export const GET_CLIENTES_DONE = 'GET_CLIENTES_DONE';
export const GET_CLIENTES_FAIL = 'GET_CLIENTES_FAIL';

export const STORE_CLIENTE_INIT = 'STORE_CLIENTE_INIT';
export const STORE_CLIENTE_DONE = 'STORE_CLIENTE_DONE';
export const STORE_CLIENTE_FAIL = 'STORE_CLIENTE_FAIL';

export const UPDATE_CLIENTE_INIT = 'UPDATE_CLIENTE_INIT';
export const UPDATE_CLIENTE_DONE = 'UPDATE_CLIENTE_DONE';
export const UPDATE_CLIENTE_FAIL = 'UPDATE_CLIENTE_FAIL';

export const DELETE_CLIENTE_INIT = 'DELETE_CLIENTE_INIT';
export const DELETE_CLIENTE_DONE = 'DELETE_CLIENTE_DONE';
export const DELETE_CLIENTE_FAIL = 'DELETE_CLIENTE_FAIL';

export const ADD_TO_FILES_COUNT = 'ADD_TO_FILES_COUNT';
