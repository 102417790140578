import Http from '../../../libs/Http';
import Storage from '../../../libs/Storage';
import getErrors from '../../../helpers/getErrors';
import * as creators from './creators/months';

export const getMonths = () => {
  return async (dispatch) => {
    dispatch(creators.getMonthsInit());

    try {
      const token = Storage.instance.get('token-mjAR4g');
      const response = await Http.instance.get(`months?token=${token}`);

      dispatch(creators.getMonthsDone(response.data));
    } catch (error) {
      dispatch(creators.getMonthsFail(getErrors(error)));
    }
  };
};

export const addToFilesCount = (id, number) =>
  (dispatch) => dispatch(creators.addToFilesCount(id, number));
