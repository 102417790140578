import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';

const RouteGuard = (props) => {
  const {
    passes,
    redirect = '/iniciar-sesion',
    children,
  } = props;

  return passes ? children : (
    <Navigate
      to={{
        pathname: redirect,
        state: { from: props.location }
      }}
      replace
    />
  );
};

RouteGuard.propTypes = {
  passes: PropTypes.bool,
  redirect: PropTypes.string
};

export default RouteGuard;
