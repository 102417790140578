import { Fragment } from 'react';
import classNames from 'classnames';
import Nav from 'react-bootstrap/Nav';
import {
  House,
  Files,
  FilePerson,
  People,
  Gear,
  BoxArrowRight
} from 'react-bootstrap-icons';
import useNavigation from '../../hooks/useNavigation';
import NavLink from '../UI/NavLink';
import Confirm from '../UI/Confirm';
import styles from './Navigation.module.scss';

const Navigation = () => {
  const {
    isShowLogout,
    canAccessClientes,
    canAccessContadores,
    onShowLogout,
    onCancelLogout,
    onLogout
  } = useNavigation();

  return (
    <Fragment>
      <Nav className="flex-column">
        <Nav.Link
          as={NavLink}
          to="/"
          className={classNames(styles.Link, 'mb-2')}
          activeClassName={styles.Active}
        >
          <House className={classNames(styles.Icon, 'me-3')} />
          Inicio
        </Nav.Link>
        <Nav.Link
          as={NavLink}
          to="/archivos"
          className={classNames(styles.Link, 'mb-2')}
          activeClassName={styles.Active}
        >
          <Files className={classNames(styles.Icon, 'me-3')} />
          Archivos
        </Nav.Link>
        {canAccessClientes() ? (
          <Nav.Link
            as={NavLink}
            to="/clientes"
            className={classNames(styles.Link, 'mb-2')}
            activeClassName={styles.Active}
          >
            <FilePerson className={classNames(styles.Icon, 'me-3')} />
            Clientes
          </Nav.Link>
        ) : null}
        {canAccessContadores() ? (
          <Nav.Link
            as={NavLink}
            to="/contadores"
            className={classNames(styles.Link, 'mb-2')}
            activeClassName={styles.Active}
          >
            <People className={classNames(styles.Icon, 'me-3')} />
            Usuarios
          </Nav.Link>
        ) : null}
        <Nav.Link
          as={NavLink}
          to="/administracion"
          className={classNames(styles.Link, 'mb-2')}
          activeClassName={styles.Active}
        >
          <Gear className={classNames(styles.Icon, 'me-3')} />
          Administración
        </Nav.Link>
        <Nav.Link
          className={styles.Link}
          onClick={onShowLogout}
        >
          <BoxArrowRight className={classNames(styles.Icon, 'me-3')} />
          Cerrar sesión
        </Nav.Link>
      </Nav>
      <Confirm
        show={isShowLogout}
        title="¿Cerrar sesión?"
        content="Confirma si realmente deseas cerrar sesión."
        cancelButton="No, cancelar"
        confirmButton="Sí, cerrar sesión"
        onCancel={onCancelLogout}
        onConfirm={onLogout}
      />
    </Fragment>
  );
};

export default Navigation;
