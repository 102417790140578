import * as types from '../types/files';

export const getFilesInit = () => ({
  type: types.GET_FILES_INIT
});

export const getFilesDone = (files) => ({
  type: types.GET_FILES_DONE,
  files
});

export const getFilesFail = (errors) => ({
  type: types.GET_FILES_FAIL,
  errors
});

export const storeFileInit = () => ({
  type: types.STORE_FILE_INIT
});

export const storeFileDone = (file) => ({
  type: types.STORE_FILE_DONE,
  file
});

export const storeFileFail = (errors) => ({
  type: types.STORE_FILE_FAIL,
  errors
});

export const updateFileInit = () => ({
  type: types.UPDATE_FILE_INIT
});

export const updateFileDone = (file) => ({
  type: types.UPDATE_FILE_DONE,
  file
});

export const updateFileFail = (errors) => ({
  type: types.UPDATE_FILE_FAIL,
  errors
});

export const deleteFileInit = () => ({
  type: types.DELETE_FILE_INIT
});

export const deleteFileDone = (file) => ({
  type: types.DELETE_FILE_DONE,
  file
});

export const deleteFileFail = (errors) => ({
  type: types.DELETE_FILE_FAIL,
  errors
});

export const getClientesInit = () => ({
  type: types.GET_CLIENTES_INIT
});

export const getClientesDone = (clientes) => ({
  type: types.GET_CLIENTES_DONE,
  clientes
});

export const getClientesFail = (errors) => ({
  type: types.GET_CLIENTES_FAIL,
  errors
});

export const getYearsInit = () => ({
  type: types.GET_YEARS_INIT
});

export const getYearsDone = (years) => ({
  type: types.GET_YEARS_DONE,
  years
});

export const getYearsFail = (errors) => ({
  type: types.GET_YEARS_FAIL,
  errors
});

export const getMothsInit = () => ({
  type: types.GET_MONTHS_INIT
});

export const getMothsDone = (months) => ({
  type: types.GET_MONTHS_DONE,
  months
});

export const getMothsFail = (errors) => ({
  type: types.GET_MONTHS_FAIL,
  errors
});

export const addFileToFiles = (file) => ({
  type: types.ADD_FILE_TO_FILES,
  file
});

export const removeFileFromFiles = (id) => ({
  type: types.REMOVE_FILE_FROM_FILES,
  id
});
