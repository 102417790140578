import { useSelector, useDispatch } from 'react-redux';
import * as actions from '../store/global/authSlice';

export default function useAuth() {
  const user = useSelector((state) => state.auth.user);
  const isAuthenticated = useSelector((state) => !!state.auth.token);
  const isVerifying = useSelector((state) => state.auth.isVerifying);
  const isUpdating = useSelector((state) => state.auth.isUpdating);
  const isUpdated = useSelector((state) => state.auth.isUpdated);
  const dispatch = useDispatch();

  const checkIfUserIsAuthenticated = () => {
    dispatch(actions.checkIfUserIsAuthenticated());
  };

  const logout = () => {
    dispatch(actions.logout());
  };

  return {
    user,
    isAuthenticated,
    isVerifying,
    isUpdating,
    isUpdated,
    checkIfUserIsAuthenticated,
    logout
  };
}
