import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate
} from 'react-router-dom';
import useApp from './hooks/useApp';
import Layout from './components/Layout';
import RouteGuard from './components/RouteGuard';
import Login from './pages/Login';
import Home from './pages/Home';
import Files from './pages/Files';
import Contadores from './pages/Contadores';
import Clientes from './pages/Clientes';
import Administracion from './pages/Administracion';

function App() {
  const {
    isAuthenticated,
    isVerifying,
    canAccessClientes,
    canAccessContadores
  } = useApp();

  if (isVerifying) {
    return 'Cargando...';
  }

  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={<Layout />}
        >
          <Route
            index
            element={(
              <RouteGuard
                passes={isAuthenticated}
              >
                <Home />
              </RouteGuard>
            )}
          />
          <Route
            path="/archivos/*"
            element={(
              <RouteGuard
                passes={isAuthenticated}
              >
                <Files />
              </RouteGuard>
            )}
          />
          <Route
            path="/clientes"
            element={(
              <RouteGuard
                passes={canAccessClientes()}
              >
                <Clientes />
              </RouteGuard>
            )}
          />
          <Route
            path="/contadores"
            element={(
              <RouteGuard
                passes={canAccessContadores()}
              >
                <Contadores />
              </RouteGuard>
            )}
          />
          <Route
            path="/administracion"
            element={(
              <RouteGuard
                passes={isAuthenticated}
              >
                <Administracion />
              </RouteGuard>
            )}
          />
        </Route>
        <Route
          path="/iniciar-sesion"
          element={(
            <RouteGuard
                passes={!isAuthenticated}
                redirect="/"
            >
              <Login />
            </RouteGuard>
          )}
        />
        <Route
          path="*"
          element={(
            <Navigate
              to="/"
              replace
            />
          )}
        />
      </Routes>
    </Router>
  );
}

export default App;
