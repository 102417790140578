import Http from '../../../libs/Http';
import Storage from '../../../libs/Storage';
import getErrors from '../../../helpers/getErrors';
import * as creators from './creators/contadores';

export const getContadores = (params = {}) => {
  return async (dispatch) => {
    dispatch(creators.getContadoresInit());

    try {
      const token = Storage.instance.get('token-mjAR4g');
      const response = await Http.instance.get(`contadores?token=${token}`, { params });

      dispatch(creators.getContadoresDone(response.data));
    } catch (error) {
      dispatch(creators.getContadoresFail(getErrors(error)));
    }
  };
};

export const storeContador = (contador, params = {}) => {
  return async (dispatch) => {
    dispatch(creators.storeContadorInit());

    try {
      const token = Storage.instance.get('token-mjAR4g');
      const response = await Http.instance.post(`contadores?token=${token}`, contador, { params });

      dispatch(creators.storeContadorDone(response.data));
      return true;
    } catch (error) {
      dispatch(creators.storeContadorFail(getErrors(error)));
      return false;
    }
  };
};

export const updateContador = (contador) => {
  return async (dispatch) => {
    dispatch(creators.updateContadorInit());

    try {
      const token = Storage.instance.get('token-mjAR4g');
      const response = await Http.instance.put(`contadores/${contador.id}?token=${token}`, contador);

      dispatch(creators.updateContadorDone(response.data));
      return true;
    } catch (error) {
      dispatch(creators.updateContadorFail(getErrors(error)));
      return false;
    }
  };
};

export const deleteContador = (id) => {
  return async (dispatch) => {
    dispatch(creators.deleteContadorInit());

    try {
      const token = Storage.instance.get('token-mjAR4g');
      const response = await Http.instance.delete(`contadores/${id}?token=${token}`);

      dispatch(creators.deleteContadorDone(response.data));
      return true;
    } catch (error) {
      dispatch(creators.deleteContadorFail(getErrors(error)));
      return false;
    }
  };
};
