export default function dateTo(value, dateStyle = null, timeStyle = null) {
  const date = new Date(value);

  const options = {
    ...(dateStyle && {dateStyle}),
    ...(timeStyle && {timeStyle})
  };

  return new Intl.DateTimeFormat('es-MX', options).format(date);
}
