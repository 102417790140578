import isObject from './isObject';

const defaultErrors = {
  object: null,
  string: null
};

export default function getErrors(error) {
  const errors = error?.response?.data?.errors
              || error?.response?.data?.message
              || error?.response?.statusText;

  if (isObject(errors)) {
    return {
      ...defaultErrors,
      object: Object.keys(errors)
        .reduce((obj, key) => {
          obj[key] = errors[key][0];

          return obj;
        }, {})
    };
  }

  return {
    ...defaultErrors,
    string: errors
  };
}
