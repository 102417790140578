import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import useFilesUpload from '../../../hooks/useFilesUpload';
import bytesToSize from '../../../helpers/bytesToSize';
import Modal from '../../UI/Modal';
import Input from '../../UI/Input';
import Select from '../../UI/Select';
import File from '../../UI/File';
import List from '../../UI/List';
import Button from '../../UI/Button';

const Upload = () => {
  const {
    clientes,
    years,
    months,
    formik,
    isShowUpload,
    onHideUpload
  } = useFilesUpload();

  const onCancel = () => {
    formik.resetForm();
    onHideUpload();
  };

  return (
    <Modal
      show={isShowUpload}
      onHide={onCancel}
    >
      <Modal.Header>
        Subir archivo
      </Modal.Header>
      <Modal.Body>
        <Form noValidate>
          <Form.Group
            className="mb-3"
            controlId="user_id"
          >
            <Form.Label>Cliente</Form.Label>
            <Select
              name="user_id"
              disabled={clientes.loading}
              isInvalid={!!formik.errors.user_id && formik.touched.user_id}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.user_id}
            >
              <option value="">
                {clientes.loading ? 'Cargando...' : 'Seleccionar...'}
              </option>
              {clientes.data.map((cliente) => (
                <option
                  key={cliente.id}
                  value={cliente.id}
                >
                  {cliente.full_name}
                </option>
              ))}
            </Select>
            <Form.Control.Feedback type="invalid">
              {formik.errors.user_id}
            </Form.Control.Feedback>
          </Form.Group>
          <Row className="mb-3">
            <Form.Group
              as={Col}
              controlId="year_id"
            >
              <Form.Label>Año</Form.Label>
              <Select
                name="year_id"
                disabled={years.loading}
                isInvalid={!!formik.errors.year_id && formik.touched.year_id}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.year_id}
              >
                <option value="">
                  {years.loading ? 'Cargando...' : 'Seleccionar...'}
                </option>
                {years.data.map((year) => (
                  <option
                    key={year.id}
                    value={year.id}
                  >
                    {year.year}
                  </option>
                ))}
              </Select>
              <Form.Control.Feedback type="invalid">
                {formik.errors.year_id}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group
              as={Col}
              controlId="month_id"
            >
              <Form.Label>Mes</Form.Label>
              <Select
                name="month_id"
                disabled={months.loading}
                isInvalid={!!formik.errors.month_id && formik.touched.month_id}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.month_id}
              >
                <option value="">
                  {months.loading ? 'Cargando...' : 'Seleccionar...'}
                </option>
                {months.data.map((month) => (
                  <option
                    key={month.id}
                    value={month.id}
                  >
                    {month.name}
                  </option>
                ))}
              </Select>
              <Form.Control.Feedback type="invalid">
                {formik.errors.month_id}
              </Form.Control.Feedback>
            </Form.Group>
          </Row>
          <Form.Group
            className="mb-3"
            controlId="title"
          >
            <Form.Label>Título</Form.Label>
            <Input
              type="text"
              name="title"
              isInvalid={!!formik.errors.title && formik.touched.title}
              maxLength={150}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.title}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.title}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="file">
            <Form.Label>Archivo</Form.Label>
            <File
              name="file"
              isInvalid={!!formik.errors.file}
              onChange={formik.setFieldValue}
            />
            {formik.values.file ? (
              <List className="mt-2">
                <List.Item>
                  <div className="fw-bold">{formik.values.file.name}</div>
                  <span className="text-muted">{bytesToSize(formik.values.file.size)}</span>
                </List.Item>
              </List>
            ) : null}
            {!formik.errors.file ? (
              <Form.Text muted>
                Tipos de archivos aceptados: PDF (.pdf), Zip (.zip), Word (.doc y .docx), Excel (.xls y .xlsx) y PowerPoint (.ppt y .pptx).
              </Form.Text>
            ) : null}
            <Form.Control.Feedback type="invalid">
              {formik.errors.file}
            </Form.Control.Feedback>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="link"
          disabled={formik.isSubmitting}
          onClick={onCancel}
        >
          Cancelar
        </Button>
        <Button
          disabled={formik.isSubmitting}
          onClick={formik.handleSubmit}
        >
          {formik.isSubmitting ? 'Guardando...' : 'Guardar'}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default Upload;
