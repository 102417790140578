import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Dropdown from 'react-bootstrap/Dropdown';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import { Trash } from 'react-bootstrap-icons';
import useClientes from '../../hooks/useClientes';
import dateTo from '../../helpers/dateTo';
import Alert from '../../components/UI/Alert';
import Title from '../../components/Title';
import Header from '../../components/Users/Header';
import Table from '../../components/UI/Table';
import Button from '../../components/UI/Button';
import DropdownToggle from '../../components/UI/DropdownToggle';
import DropdownMenu from '../../components/UI/DropdownMenu';
import DropdownItem from '../../components/UI/DropdownItem';
import Paginator from '../../components/UI/Paginator';
import Confirm from '../../components/UI/Confirm';
import NewOrEdit from '../../components/Users/NewOrEdit';

const Clientes = () => {
  const {
    clientes,
    pagination,
    isLoading,
    isSaved,
    isDeleting,
    selectedCliente,
    isShowNewOrEdit,
    isShowSavedMessage,
    isShowConfirmDelete,
    isShowDeletedMessage,
    onSearchClientes,
    onShowNew,
    onHideNewOrEdit,
    onSaveCliente,
    onHideSavedMessage,
    onEditCliente,
    onNextOrPrevClientes,
    onShowConfirmDelete,
    onCancelConfirmDelete,
    onDeleteCliente,
    onHideDeletedMessage
  } = useClientes();

  return (
    <>
      {isShowSavedMessage ? (
        <Row>
          <Col>
            <Alert
              variant="success"
              dismissible
              onClose={onHideSavedMessage}
            >
              El cliente se guardó correctamente.
            </Alert>
          </Col>
        </Row>
      ) : null}
      {isShowDeletedMessage ? (
        <Row>
          <Col>
            <Alert
              variant="success"
              dismissible
              onClose={onHideDeletedMessage}
            >
              El cliente fue eliminado exitosamente.
            </Alert>
          </Col>
        </Row>
      ) : null}
      <Row>
        <Col>
          <Title>Clientes</Title>
        </Col>
      </Row>
      <Row>
        <Col>
          <Header
            buttonText="Nuevo cliente"
            onNew={onShowNew}
            onSearch={onSearchClientes}
            placeholder="Buscar cliente"
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="bg-white">
            <Table>
              <thead>
                <tr>
                  <th>Nombre completo</th>
                  <th>Usuario</th>
                  <th>Última actualización</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {isLoading ? (
                  <tr>
                    <td
                      colSpan="4"
                      className="text-center"
                    >
                      Cargando...
                    </td>
                  </tr>
                ) : null}
                {clientes.map((cliente) => (
                  <tr key={cliente.id}>
                    <td>{cliente.full_name}</td>
                    <td>{cliente.username}</td>
                    <td>{dateTo(cliente.updated_at, 'long', 'medium')}</td>
                    <td className="text-end">
                      <Dropdown
                        as={ButtonGroup}
                        align="end"
                      >
                        <Button
                          variant="primary"
                          onClick={onEditCliente(cliente)}
                        >
                          Editar
                        </Button>
                        <DropdownToggle
                          variant="primary"
                          split
                        />
                        <DropdownMenu>
                          <DropdownItem onClick={onShowConfirmDelete(cliente.id)}>
                            <Trash /> Eliminar
                          </DropdownItem>
                        </DropdownMenu>
                      </Dropdown>
                    </td>
                  </tr>
                ))}
                {(!isLoading && !clientes.length) ? (
                  <tr>
                    <td
                      colSpan="4"
                      className="text-center"
                    >
                      No se han encontrado resultados.
                    </td>
                  </tr>
                ) : null}
              </tbody>
            </Table>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <Paginator
            className="mb-0"
            currentPage={pagination.current_page}
            perPage={pagination.per_page}
            total={pagination.total}
            onPageChange={onNextOrPrevClientes}
          />
        </Col>
      </Row>
      <NewOrEdit
        user={selectedCliente}
        isShow={isShowNewOrEdit}
        isSaved={isSaved}
        onCancel={onHideNewOrEdit}
        onSave={onSaveCliente}
      />
      <Confirm
        show={isShowConfirmDelete}
        title="¿Eliminar cliente?"
        content="El cliente y sus archivos serán eliminados permanentemente."
        loading={isDeleting}
        loadingText="Eliminando..."
        cancelButton="No, cancelar"
        confirmButton="Sí, eliminar"
        onCancel={onCancelConfirmDelete}
        onConfirm={onDeleteCliente}
      />
    </>
  );
};

export default Clientes;
