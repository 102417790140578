import axios from 'axios';
import * as backend from '../constants/backend';

class Http {
    static instance = new Http();

    constructor() {
        this.client = axios.create({
            baseURL: `${backend.PROTOCOL}${backend.SUBDOMAIN}.${backend.DOMAIN}/api/`
        });
    }

    async get(url, config = {}) {
        return await this.client.get(url, config);
    }

    async post(url, data = {}, config = {}) {
        return await this.client.post(url, data, config);
    }

    async put(url, data = {}, config = {}) {
        return await this.client.put(url, data, config);
    }

    async delete(url, config = {}) {
        return await this.client.delete(url, config);
    }
}

export default Http;
