import * as types from '../actions/types/files-archivos';

export const initialState = {
  files: [],
  isLoading: true,
  isDeleting: false,
  isDeleted: false,
  errors: null
};

export const reducer = (state, action) => {
  switch (action.type) {
    case types.GET_FILES_INIT:
      return {
        ...state,
        files: [],
        isLoading: true,
        errors: null
      };
    case types.GET_FILES_DONE: {
      const files = [... action.files];

      return {
        ...state,
        files,
        isLoading: false
      };
    }
    case types.GET_FILES_FAIL:
      return {
        ...state,
        isLoading: false,
        errors: action.errors
      };
    case types.ADD_FILE_TO_FILES: {
      const exists = state.files.some((file) => +file.id === +action.file.id);

      if (exists) {
        return {
          ...state,
          files: state.files.map((file) => {
            if (+file.id === action.file.id) {
              return action.file;
            }

            return file;
          })
        };
      }

      return {
        ...state,
        files: [...state.files, action.file]
      };
    }
    case types.REMOVE_FILE_FROM_FILES: {
      const files = state.files.filter((file) => +file.id !== +action.id);

      return {
        ...state,
        files
      };
    }
    case types.DELETE_FILE_INIT:
      return {
        ...state,
        isDeleting: true,
        isDeleted: false,
        errors: null
      };
    case types.DELETE_FILE_DONE: {
      const files = state.files.filter((file) => +file.id !== +action.file.id);

      return {
        ...state,
        files,
        isDeleting: false,
        isDeleted: true
      };
    }
    case types.DELETE_FILE_FAIL:
      return {
        ...state,
        isDeleting: false,
        errors: action.errors
      };
    default:
      return state;
  }
};
