import PropTypes from 'prop-types';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';
import { Search as IconSearch } from 'react-bootstrap-icons';
import Input from '../Input';
import styles from './Search.module.scss';

const Search = (props) => {
  const {
    placeholder,
    value,
    ...rest
  } = props;

  const onChange = (event) => {
    const value = event.target.value;
    rest.onChange(value);
  };

  const onKeyDown = (event) => {
    if (event.key === 'Enter') {
      onSearch();
    }
  };

  const onSearch = () => {
    rest.onSearch();
  };

  return (
    <InputGroup>
      <Input
        type="text"
        className={styles.Input}
        onKeyDown={onKeyDown}
        onChange={onChange}
        placeholder={placeholder}
        value={value}
      />
      <Button
        className={styles.Label}
        onClick={onSearch}
      >
        <IconSearch />
      </Button>
    </InputGroup>
  );
};

Search.propTypes = {
  onChange: PropTypes.func,
  onSearch: PropTypes.func,
  placeholder: PropTypes.string,
  value: PropTypes.string
};

export default Search;
