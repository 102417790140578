import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import useAdministracion from '../../hooks/useAdministracion';
import Alert from '../../components/UI/Alert';
import Title from '../../components/Title';
import Input from '../../components/UI/Input';
import Button from '../../components/UI/Button';

const Administracion = () => {
  const {
    formik,
    isUpdating,
    isShowUpdatedMessage,
    isCliente,
    onHideUpdatedMessage
  } = useAdministracion();

  return (
    <>
      {isShowUpdatedMessage ? (
        <Row>
          <Col sm="10" md="8" lg="6">
            <Alert
              variant="success"
              dismissible
              onClose={onHideUpdatedMessage}
            >
              Su perfil se ha actualizado correctamente.
            </Alert>
          </Col>
        </Row>
      ) : null}
      <Row>
        <Col sm="10" md="8" lg="6">
          <Title>Administración</Title>
        </Col>
      </Row>
      <Row>
        <Col sm="10" md="8" lg="6">
          <Form
            noValidate
            onSubmit={formik.handleSubmit}
          >
            {!isCliente() ? (
              <>
                <Form.Group
                  className="mb-3"
                  controlId="full_name"
                >
                  <Form.Label>Nombre completo</Form.Label>
                  <Input
                    type="text"
                    name="full_name"
                    isInvalid={!!formik.errors.full_name && formik.touched.full_name}
                    maxLength={150}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.full_name}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.full_name}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="username"
                >
                  <Form.Label>Usuario</Form.Label>
                  <Input
                    type="text"
                    name="username"
                    isInvalid={!!formik.errors.username && formik.touched.username}
                    maxLength={50}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.username}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.username}
                  </Form.Control.Feedback>
                </Form.Group>
              </>
            ) : null}
            <Form.Group
              className="mb-3"
              controlId="password"
            >
              <Form.Label>Contraseña</Form.Label>
              <Input
                type="password"
                name="password"
                isInvalid={!!formik.errors.password && formik.touched.password}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.password}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.password}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group
              className="mb-3"
              controlId="password_confirmation"
            >
              <Form.Label>Confirmar contraseña</Form.Label>
              <Input
                type="password"
                name="password_confirmation"
                isInvalid={!!formik.errors.password_confirmation && formik.touched.password_confirmation}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.password_confirmation}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.password_confirmation}
              </Form.Control.Feedback>
            </Form.Group>
            <Button
              type="submit"
              className="d-block me-0 ms-auto"
              disabled={isUpdating}
            >
              {isUpdating ? 'Guardando...' : 'Guardar'}
            </Button>
          </Form>
        </Col>
      </Row>
    </>
  );
};

export default Administracion;
