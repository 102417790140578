import { createContext } from 'react';

const FilesContext = createContext({
  file: null,
  search: null,
  selectedFile: null,
  isArchivo: false,
  isShowUpload: false,
  isUploadedSuccessfully: false,
  isDeletedSuccessfully: false,
  onShowUpload: () => {},
  onHideUpload: () => {},
  onShowUploadedSuccessfully: (file) => {},
  onSelectFile: (file) => {},
  onShowDeletedSuccessfully: () => {},
  onSearch: (search) => {}
});

export default FilesContext;
