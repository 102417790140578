import classNames from 'classnames';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import {
  Outlet
} from 'react-router-dom';
import logo from '../../assets/logo.png';
import Navigation from '../Navigation';
import styles from './Layout.module.scss';

const Layout = () => {
  return (
    <Container fluid>
      <Row className={styles.Row}>
        <Col
          className={classNames(styles.Sidebar, 'd-flex flex-column px-0')}
          xs={12}
          md={3}
          xl={2}
        >
          <div className={classNames(styles.Logo, 'd-flex justify-content-between align-items-center mb-3 py-3')}>
            <img
              src={logo}
              className={styles.LogoImage}
              alt="Logotipo de Despacho Nieto S.C."
            />
          </div>
          <Navigation />
        </Col>
        <Col
          className="px-0"
          xs={12}
          md={9}
          xl={10}
        >
          <Container
            fluid
            className="p-5"
          >
            <Outlet />
          </Container>
        </Col>
      </Row>
    </Container>
  );
};

export default Layout;
