import { useState, useEffect, useReducer } from 'react';
import { initialState, reducer } from '../store/local/reducers/clientes';
import * as actions from '../store/local/actions/clientes';

export default function useClientes() {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const [selectedCliente, setSelectedCliente] = useState(null);
  const [selectedClienteId, setSelectedClienteId] = useState(null);
  const [isShowNewOrEdit, setIsShowNewOrEdit] = useState(false);
  const [isShowSavedMessage, setIsShowSavedMessage] = useState(false);
  const [isShowConfirmDelete, setIsShowConfirmDelete] = useState(false);
  const [isShowDeletedMessage, setIsShowDeletedMessage] = useState(false);

  useEffect(() => {
    getClientes({ page, search });
  }, [page, search]);

  useEffect(() => {
    showOrHideSavedMessage();
  }, [state.isSaved]);

  const getClientes = (params = {}) => {
    actions.getClientes(params)(dispatch);
  };

  const showOrHideSavedMessage = () => {
    setIsShowSavedMessage(state.isSaved);
  };

  const onSearchClientes = (search) => {
    setPage(1);
    setSearch(search);
  };

  const onShowNew = () => {
    setIsShowNewOrEdit(true);
  };

  const onHideNewOrEdit = () => {
    setSelectedCliente(null);
    setIsShowNewOrEdit(false);
  };

  const onSaveCliente = async (cliente) => {
    setIsShowSavedMessage(false);
    setIsShowDeletedMessage(false);

    !selectedCliente && await actions.storeCliente(cliente, { page })(dispatch);
    selectedCliente && await actions.updateCliente(cliente)(dispatch);
  };

  const onHideSavedMessage = () => {
    setIsShowSavedMessage(false);
  };

  const onEditCliente = (cliente) => () => {
    setSelectedCliente(cliente);
    setIsShowNewOrEdit(true);
  };

  const onNextOrPrevClientes = (page) => {
    setPage(page);
  };

  const onShowConfirmDelete = (id) => () => {
    setSelectedClienteId(id);
    setIsShowConfirmDelete(true);
  };

  const onCancelConfirmDelete = () => {
    setSelectedClienteId(null);
    setIsShowConfirmDelete(false);
  };

  const onDeleteCliente = async () => {
    setIsShowSavedMessage(false);
    setIsShowDeletedMessage(false);

    if (await actions.deleteCliente(selectedClienteId)(dispatch)) {
      setIsShowDeletedMessage(true);
      onCancelConfirmDelete();
    }
  };

  const onHideDeletedMessage = () => {
    setIsShowDeletedMessage(false);
  };

  return {
    clientes: state.clientes,
    pagination: state.pagination,
    isLoading: state.isLoading,
    isSaved: state.isSaved,
    isDeleting: state.isDeleting,
    selectedCliente,
    isShowNewOrEdit,
    isShowSavedMessage,
    isShowConfirmDelete,
    isShowDeletedMessage,
    onSearchClientes,
    onShowNew,
    onHideNewOrEdit,
    onSaveCliente,
    onHideSavedMessage,
    onEditCliente,
    onNextOrPrevClientes,
    onShowConfirmDelete,
    onCancelConfirmDelete,
    onDeleteCliente,
    onHideDeletedMessage
  };
}
