import { useEffect } from 'react';
import useAuth from './useAuth';

export default function useApp() {
  const {
    user,
    isAuthenticated,
    isVerifying,
    checkIfUserIsAuthenticated
  } = useAuth();

  useEffect(() => {
    checkIfUserIsAuthenticated();
  }, []);

  const canAccessClientes = () => {
    return user?.is_administrador || user?.is_contador;
  };

  const canAccessContadores = () => {
    return user?.is_administrador;
  };

  return {
    isAuthenticated,
    isVerifying,
    canAccessClientes,
    canAccessContadores
  };
}
