import { useState, useEffect, useContext } from 'react';
import { useLocation, useMatch } from 'react-router-dom';
import classNames from 'classnames';
import FilesContext from '../../../contexts/FilesContext';
import useAuth from '../../../hooks/useAuth';
import Search from '../../UI/Search';
import Button from '../../UI/Button';

const Header = () => {
  const [search, setSearch] = useState('');
  const location = useLocation();
  const matchExactFilesRoute = useMatch('/archivos/:userId/archivos');
  const matchClienteExcatFilesRoute = useMatch('/archivos/archivos');
  const { user } = useAuth();

  const {
    isArchivo,
    onShowUpload,
    ...rest
  } = useContext(FilesContext);

  useEffect(() => {
    resetSearch();
  }, [location]);

  const canUploadFile = () => {
    return !user?.is_cliente;
  };

  const resetSearch = () => {
    if (!matchClienteExcatFilesRoute && !matchExactFilesRoute) {
      setSearch('');
    }
  };

  const getPlaceholder = () => {
    if (user?.is_cliente) {
      return 'Buscar archivo';
    }

    return isArchivo ? 'Buscar archivo' : 'Buscar cliente';
  };

  const onChange = (value) => {
    setSearch(value);
  };

  const onSearch = () => {
    rest.onSearch(search);
  };

  return (
    <div className="bg-white d-flex align-items-center p-4">
      <div
        className={classNames('flex-grow-1', {
          'me-5': canUploadFile()
        })}
      >
        <Search
          onChange={onChange}
          onSearch={onSearch}
          placeholder={getPlaceholder()}
          value={search}
        />
      </div>
      {canUploadFile() ? (
        <div>
          <Button onClick={onShowUpload}>
            Subir archivo
          </Button>
        </div>
      ) : null}
    </div>
  );
};

export default Header;
