import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './Title.module.scss';

const Title = (props) => {
  const {
    className,
    children,
    ...rest
  } = props;

  return (
    <h1
      {...rest}
      className={classNames('h4 mb-5', styles.Title, className)}
    >
      {children}
    </h1>
  );
};

Title.propTypes = {
  className: PropTypes.string
};

export default Title;
