import { Link } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Folder } from 'react-bootstrap-icons';
import useFilesMeses from '../../../hooks/useFilesMeses';
import Table from '../../../components/UI/Table';

const Meses = () => {
  const {
    months,
    isLoading,
    hasMonths,
    getLinkState
  } = useFilesMeses();

  return (
    <Row>
      <Col>
        <div className="bg-white">
          <Table>
            <thead>
              <tr>
                <th>Mes</th>
                <th>Total de archivos</th>
              </tr>
            </thead>
            <tbody>
              {isLoading ? (
                <tr>
                  <td
                    colSpan={2}
                    className="text-center"
                  >
                    Cargando...
                  </td>
                </tr>
              ) : null}
              {months.map((month) => (
                <tr key={month.id}>
                  <td>
                    <Link
                      to={`${month.id}`}
                      state={getLinkState(month)}
                    >
                      <Folder /> {month.name}
                    </Link>
                  </td>
                  <td>{month.files_count}</td>
                </tr>
              ))}
              {(!isLoading && !hasMonths) ? (
                <tr>
                  <td
                    colSpan={2}
                    className="text-center"
                  >
                    No se han encontrado resultados.
                  </td>
                </tr>
              ) : null}
            </tbody>
          </Table>
        </div>
      </Col>
    </Row>
  );
};

export default Meses;
