import { useMemo } from 'react';
import PropTypes from 'prop-types';
import Pagination from 'react-bootstrap/Pagination';
import styles from './Paginator.module.scss';

const getRange = (start, end) => {
  return Array(end - start + 1)
    .fill()
    .map((_, i) => i + start)
};

const Paginator = (props) => {
  const {
    className,
    currentPage,
    perPage,
    total,
    onPageChange
  } = props;

  const totalPages = Math.ceil(total / perPage) || 1;

  const pages = useMemo(() => {
    let delta = 0;
    let start = 0;
    let end = 0;

    if (totalPages <= 7) {
      delta = 7;
    } else {
      delta = currentPage > 4 && currentPage < totalPages - 3 ? 2 : 4;
    }

    start = Math.round(currentPage - delta / 2);
    end = Math.round(currentPage + delta / 2);

    if (start - 1 === 1 || end + 1 === totalPages) {
      start += 1
      end += 1
    }

    let pages = (currentPage > delta)
      ? getRange(Math.min(start, totalPages - delta), Math.min(end, totalPages))
      : getRange(1, Math.min(totalPages, delta + 1));

    const withDots = (value, pair) => (pages.length + 1 !== totalPages ? pair : [value]);

    if (pages[0] !== 1) {
      pages = [...withDots(1, [1, '...']), ...pages];
    }

    if (pages[pages.length - 1] < totalPages) {
      pages = [...pages, ...withDots(totalPages, ['...', totalPages])];
    }

    return pages;
  }, [currentPage, perPage, total]);

  const isDisabled = (page) => {
    return currentPage === page;
  };

  const onCurrent = (page) => () => {
    if (currentPage !== page) {
      onPageChange(page);
    }
  };

  const onPrev = () => {
    if (currentPage > 1) {
      onPageChange(currentPage - 1);
    }
  };

  const onNext = () => {
    if (currentPage < totalPages) {
      onPageChange(currentPage + 1);
    }
  };

  return (
    <Pagination className={className}>
      <Pagination.First
        className={styles.Item}
        disabled={isDisabled(1)}
        onClick={onCurrent(1)}
      />
      <Pagination.Prev
        className={styles.Item}
        disabled={isDisabled(1)}
        onClick={onPrev}
      />
      {pages.map((page, index) => {
        if (page === '...') {
          return (
            <Pagination.Ellipsis
              key={index}
              className={styles.Item}
            />
          );
        }

        return (
          <Pagination.Item
            key={index}
            className={styles.Item}
            active={page === currentPage}
            onClick={onCurrent(page)}
          >
            {page}
          </Pagination.Item>
        );
      })}
      <Pagination.Next
        className={styles.Item}
        disabled={isDisabled(totalPages)}
        onClick={onNext}
      />
      <Pagination.Last
        className={styles.Item}
        disabled={isDisabled(totalPages)}
        onClick={onCurrent(totalPages)}
      />
    </Pagination>
  );
};

Paginator.propTypes = {
  className: PropTypes.string,
  currentPage: PropTypes.number,
  perPage: PropTypes.number,
  total: PropTypes.number,
  onPageChange: PropTypes.func
};

export default Paginator;
