import { Routes, Route } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import FilesContext from '../../contexts/FilesContext';
import useFiles from '../../hooks/useFiles';
import Alert from '../../components/UI/Alert';
import Menu from '../../components/Files/Menu';
import Header from '../../components/Files/Header';
import Upload from '../../components/Files/Upload';
import Title from '../../components/Title';
import Clientes from './Clientes';
import Anios from './Anios';
import Meses from './Meses';
import Archivos from './Archivos';

const Files = () => {
  const {
    file,
    user,
    search,
    selectedFile,
    isArchivo,
    isShowUpload,
    isUploadedSuccessfully,
    isDeletedSuccessfully,
    onShowUpload,
    onHideUpload,
    onShowUploadedSuccessfully,
    onHideUploadedSuccessfully,
    onSelectFile,
    onShowDeletedSuccessfully,
    onHideDeletedSuccessfully,
    onSearch
  } = useFiles();

  return (
    <FilesContext.Provider
      value={{
        file,
        search,
        selectedFile,
        isArchivo,
        isShowUpload,
        isUploadedSuccessfully,
        isDeletedSuccessfully,
        onShowUpload,
        onHideUpload,
        onShowUploadedSuccessfully,
        onSelectFile,
        onShowDeletedSuccessfully,
        onSearch
      }}
      >
      {isUploadedSuccessfully && (
        <Row>
          <Col>
            <Alert
              variant="success"
              dismissible
              onClose={onHideUploadedSuccessfully}
            >
              El archivo se guardó correctamente.
            </Alert>
          </Col>
        </Row>
      )}
      {isDeletedSuccessfully && (
        <Row>
          <Col>
            <Alert
              variant="success"
              dismissible
              onClose={onHideDeletedSuccessfully}
            >
              El archivo fue eliminado exitosamente.
            </Alert>
          </Col>
        </Row>
      )}
      <Row>
        <Col>
          <Menu />
        </Col>
      </Row>
      <Row>
        <Col>
          <Title>
            Sistema de Administración de Informes Mensuales
          </Title>
        </Col>
      </Row>
      <Row>
        <Col>
          <Header />
        </Col>
      </Row>
      <Routes>
        {user?.is_cliente ? (
          <>
            <Route path="/" element={<Anios />} />
            <Route path="/anios/:yearId/meses" element={<Meses />} />
            <Route path="/anios/:yearId/meses/:monthId" element={<Archivos />} />
            <Route path="/archivos" element={<Archivos />} />
          </>
        ) : (
          <>
            <Route path="/" element={<Clientes />} />
            <Route path="/:userId/anios" element={<Anios />} />
            <Route path="/:userId/anios/:yearId/meses" element={<Meses />} />
            <Route path="/:userId/anios/:yearId/meses/:monthId" element={<Archivos />} />
            <Route path="/:userId/archivos" element={<Archivos />} />
          </>
        )}
      </Routes>
      <Upload />
    </FilesContext.Provider>
  );
};

export default Files;
