import * as types from '../actions/types/files-anios';

export const initialState = {
  years: [],
  isLoading: true,
  errors: null
};

export const reducer = (state, action) => {
  switch (action.type) {
    case types.GET_YEARS_INIT:
      return {
        ...state,
        years: [],
        isLoading: true,
        errors: null
      };
    case types.GET_YEARS_DONE: {
      const years = [... action.years];

      return {
        ...state,
        years,
        isLoading: false
      };
    }
    case types.GET_YEARS_FAIL:
      return {
        ...state,
        isLoading: false,
        errors: action.errors
      };
    case types.ADD_TO_FILES_COUNT: {
      const years = state.years.map((year) => {
        if (year.id === action.id) {
          year.files_count += action.number;
        }

        return year;
      });

      return {
        ...state,
        years
      };
    }
    default:
      return state;
  }
};
