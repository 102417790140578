import Http from '../../../libs/Http';
import Storage from '../../../libs/Storage';
import getErrors from '../../../helpers/getErrors';
import * as creators from './creators/clientes';

export const getClientes = (params = {}) => {
  return async (dispatch) => {
    dispatch(creators.getClientesInit());

    try {
      const token = Storage.instance.get('token-mjAR4g');
      const response = await Http.instance.get(`clientes?token=${token}`, { params });

      dispatch(creators.getClientesDone(response.data));
    } catch (error) {
      dispatch(creators.getClientesFail(getErrors(error)));
    }
  };
};

export const storeCliente = (cliente, params = {}) => {
  return async (dispatch) => {
    dispatch(creators.storeClienteInit());

    try {
      const token = Storage.instance.get('token-mjAR4g');
      const response = await Http.instance.post(`clientes?token=${token}`, cliente, { params });

      dispatch(creators.storeClienteDone(response.data));
      return true;
    } catch (error) {
      dispatch(creators.storeClienteFail(getErrors(error)));
      return false;
    }
  };
};

export const updateCliente = (cliente) => {
  return async (dispatch) => {
    dispatch(creators.updateClienteInit());

    try {
      const token = Storage.instance.get('token-mjAR4g');
      const response = await Http.instance.put(`clientes/${cliente.id}?token=${token}`, cliente);

      dispatch(creators.updateClienteDone(response.data));
      return true;
    } catch (error) {
      dispatch(creators.updateClienteFail(getErrors(error)));
      return false;
    }
  };
};

export const deleteCliente = (id) => {
  return async (dispatch) => {
    dispatch(creators.deleteClienteInit());

    try {
      const token = Storage.instance.get('token-mjAR4g');
      const response = await Http.instance.delete(`clientes/${id}?token=${token}`);

      dispatch(creators.deleteClienteDone(response.data));
      return true;
    } catch (error) {
      dispatch(creators.deleteClienteFail(getErrors(error)));
      return false;
    }
  };
};

export const addToFilesCount = (id, number) =>
  (dispatch) => dispatch(creators.addToFilesCount(id, number));
