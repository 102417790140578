import * as types from '../actions/types/files-clientes';

const defaultPagination = {
  current_page: 0,
  per_page: 0,
  total: 0
};

export const initialState = {
  clientes: [],
  pagination: {...defaultPagination},
  isLoading: true,
  errors: null
};

export const reducer = (state, action) => {
  switch (action.type) {
    case types.GET_CLIENTES_INIT:
      return {
        ...state,
        clientes: [],
        pagination: {...defaultPagination},
        errors: null
      };
    case types.GET_CLIENTES_DONE: {
      const {
        data: clientes,
        current_page,
        per_page,
        total
      } = action.clientes;

      const pagination = {
        current_page,
        per_page,
        total
      };

      return {
        ...state,
        clientes,
        pagination,
        isLoading: false
      };
    }
    case types.GET_CLIENTES_INIT:
      return {
        ...state,
        isLoading: false,
        errors: action.errors
      };
    case types.ADD_TO_FILES_COUNT: {
      const clientes = state.clientes.map((cliente) => {
        if (cliente.id === parseInt(action.id, 10)) {
          cliente.files_count += action.number;
        }

        return cliente;
      });

      return {
        ...state,
        clientes
      };
    }
    default:
      return state;
  }
};
