import { default as BaseDropdownItem } from 'react-bootstrap/DropdownItem';
import styles from './DropdownItem.module.scss';

const DropdownItem = (props) => {
  const {
    children,
    ...rest
  } = props;

  return (
    <BaseDropdownItem
      className={styles.DropdownItem}
      {...rest}
    >
      {children}
    </BaseDropdownItem>
  );
};

export default DropdownItem;
