import { useEffect, useReducer, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { initialState, reducer } from '../store/local/reducers/files-upload';
import FilesContext from '../contexts/FilesContext';
import * as actions from '../store/local/actions/files-upload';

const FILE_TYPES = [
  'application/pdf',
  'application/zip',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.ms-powerpoint',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation'
];

const FileSchema = Yup.object().shape({
  title: Yup.string().required('El campo "Título" es obligatorio.'),
  file: Yup.mixed()
    .when('id', {
      is: (value) => !value,
      then: Yup.mixed()
        .required('El campo "Archivo" es obligatorio.')
        .test('fileSize', 'El archivo no debe pesar más de 4 megabytes.', (value) => value && value.size <= 4194304)
        .test({
          name: 'fileType',
          test: (value) => value && FILE_TYPES.includes(value.type),
          message: 'El archivo debe ser de tipo: PDF (.pdf), Zip (.zip), Word (.doc y .docx), Excel (.xls y .xlsx) y PowerPoint (.ppt y .pptx).'
        }),
      otherwise: Yup.mixed()
        .nullable()
        .test('fileSize', 'El archivo no debe pesar más de 4 megabytes.', (value) => !value || value.size <= 4194304)
        .test({
          name: 'fileType',
          test: (value) => !value || FILE_TYPES.includes(value.type),
          message: 'El archivo debe ser de tipo: PDF (.pdf), Zip (.zip), Word (.doc y .docx), Excel (.xls y .xlsx) y PowerPoint (.ppt y .pptx).'
        }),
    }),
  month_id: Yup.string().required('El campo "Mes" es obligatorio.'),
  year_id: Yup.string().required('El campo "Año" es obligatorio.'),
  user_id: Yup.string().required('El campo "Cliente" es obligatorio.')
});

export default function useFilesUpload() {
  const [state, dispatch] = useReducer(reducer, initialState);
  const location = useLocation();

  const {
    selectedFile,
    isShowUpload,
    onHideUpload,
    onShowUploadedSuccessfully
  } = useContext(FilesContext);

  const formik = useFormik({
    initialValues: {
      title: '',
      file: '',
      month_id: '',
      year_id: '',
      user_id: ''
    },
    validationSchema: FileSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);

      !selectedFile && await actions.storeFile(values)(dispatch);
      selectedFile && await actions.updateFile(values)(dispatch);

      setSubmitting(false);
    }
  });

  useEffect(() => {
    if (isShowUpload) {
      shownUpload();
    }
  }, [isShowUpload]);

  useEffect(() => {
    if (selectedFile) {
      editFile();
    }
  }, [selectedFile]);

  useEffect(() => {
    if (state.isStored) {
      storedSuccessfully();
    }
  }, [state.isStored]);

  const shownUpload = async () => {
    const [cliente, year, month] = location.state?.menu || [];

    cliente && await formik.setFieldValue('user_id', cliente.id);
    year && await formik.setFieldValue('year_id', year.id);
    month && await formik.setFieldValue('month_id', month.id);

    formik.setErrors({});

    actions.getClientes()(dispatch);
    actions.getYears()(dispatch);
    actions.getMonths()(dispatch);
  };

  const editFile = async () => {
    await formik.setValues(selectedFile);
    formik.setErrors({});
  };

  const storedSuccessfully = () => {
    onHideUpload();
    onShowUploadedSuccessfully(state.file);
    formik.resetForm();
  };

  return {
    clientes: state.clientes,
    years: state.years,
    months: state.months,
    formik,
    isShowUpload,
    onHideUpload
  };
}
