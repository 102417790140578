import PropTypes from 'prop-types';
import { default as BaseModal } from 'react-bootstrap/Modal';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import styles from './Modal.module.scss';

const Modal = (props) => {
  const {
    show,
    onHide,
    children,
    ...rest
  } = props;

  return (
    <BaseModal
      show={show}
      backdropClassName={styles.Backdrop}
      contentClassName={styles.Content}
      onHide={onHide}
      {...rest}
    >
      {children}
    </BaseModal>
  );
};

Modal.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func
};

const Header = (props) => {
  const {
    className,
    children,
    ...rest
  } = props;

  return (
    <ModalHeader
      className={styles.Header}
      {...rest}
    >
      <ModalTitle>
        {children}
      </ModalTitle>
    </ModalHeader>
  );
};

const Body = (props) => {
  const {
    className,
    children,
    ...rest
  } = props;

  return (
    <ModalBody
      className={styles.Body}
      {...rest}
    >
      {children}
    </ModalBody>
  );
};

const Footer = (props) => {
  const {
    className,
    children,
    ...rest
  } = props;

  return (
    <ModalFooter
      className={styles.Footer}
      {...rest}
    >
      {children}
    </ModalFooter>
  );
};

Modal.Header = Header;
Modal.Body = Body;
Modal.Footer = Footer;

export default Modal;
