export const GET_FILES_INIT = 'GET_FILES_INIT';
export const GET_FILES_DONE = 'GET_FILES_DONE';
export const GET_FILES_FAIL = 'GET_FILES_FAIL';

export const STORE_FILE_INIT = 'STORE_FILE_INIT';
export const STORE_FILE_DONE = 'STORE_FILE_DONE';
export const STORE_FILE_FAIL = 'STORE_FILE_FAIL';

export const UPDATE_FILE_INIT = 'UPDATE_FILE_INIT';
export const UPDATE_FILE_DONE = 'UPDATE_FILE_DONE';
export const UPDATE_FILE_FAIL = 'UPDATE_FILE_FAIL';

export const DELETE_FILE_INIT = 'DELETE_FILE_INIT';
export const DELETE_FILE_DONE = 'DELETE_FILE_DONE';
export const DELETE_FILE_FAIL = 'DELETE_FILE_FAIL';

export const GET_CLIENTES_INIT = 'GET_CLIENTES_INIT';
export const GET_CLIENTES_DONE = 'GET_CLIENTES_DONE';
export const GET_CLIENTES_FAIL = 'GET_CLIENTES_FAIL';

export const GET_YEARS_INIT = 'GET_YEARS_INIT';
export const GET_YEARS_DONE = 'GET_YEARS_DONE';
export const GET_YEARS_FAIL = 'GET_YEARS_FAIL';

export const GET_MONTHS_INIT = 'GET_MONTHS_INIT';
export const GET_MONTHS_DONE = 'GET_MONTHS_DONE';
export const GET_MONTHS_FAIL = 'GET_MONTHS_FAIL';

export const ADD_FILE_TO_FILES = 'ADD_FILE_TO_FILES';
export const REMOVE_FILE_FROM_FILES = 'REMOVE_FILE_FROM_FILES';
