import classNames from 'classnames';
import { default as BaseTable } from 'react-bootstrap/Table';
import styles from './Table.module.scss';

const Table = (props) => {
  const {
    className,
    children,
    ...rest
  } = props;

  return (
    <BaseTable
      className={classNames('table-borderless', styles.Table, className)}
      {...rest}
    >
      {children}
    </BaseTable>
  );
};

export default Table;
