import { forwardRef } from 'react';
import { NavLink as BaseNavLink } from 'react-router-dom';

const NavLink = forwardRef(({ activeClassName, activeStyle, ...props }, ref) => {
  const classNames = ({ isActive }) => {
    return [
      props.className,
      isActive ? activeClassName : null
    ]
    .filter(Boolean)
    .join(' ');
  };

  const styles = ({ isActive }) => {
    return {
      ...props.style,
      ...(isActive ? activeStyle : null)
    };
  };

  return (
    <BaseNavLink
      ref={ref}
      {...props}
      className={classNames}
      style={styles}
    />
  );
});

export default NavLink;
