import PropTypes from 'prop-types';
import classNames from 'classnames';
import { default as BaseButton } from 'react-bootstrap/Button';
import styles from './Button.module.scss';

const Button = (props) => {
  const {
    variant = 'primary',
    className,
    children,
    ...rest
  } = props;

  return (
    <BaseButton
      variant={variant}
      className={classNames(className, {
        [styles.Primary]: variant === 'primary',
        [styles.Link]: variant === 'link'
      })}
      {...rest}
    >
      {children}
    </BaseButton>
  );
};

Button.propTypes = {
  variant: PropTypes.string,
  onClick: PropTypes.func
};

export default Button;
