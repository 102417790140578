import * as types from '../actions/types/files-upload';

const defaultClientes = {
  data: [],
  loading: true
};

const defaultYears = {
  data: [],
  loading: true
};

const defaultMonths = {
  data: [],
  loading: true
};

export const initialState = {
  file: null,
  clientes: {...defaultClientes},
  years: {...defaultYears},
  months: {...defaultMonths},
  isStored: false,
  errors: null
};

export const reducer = (state, action) => {
  switch (action.type) {
    case types.STORE_FILE_INIT:
    case types.UPDATE_FILE_INIT:
      return {
        ...state,
        file: null,
        isStored: false,
        errors: null
      };
    case types.STORE_FILE_DONE:
    case types.UPDATE_FILE_DONE: {
      const file = {...action.file};

      return {
        ...state,
        file,
        isStored: true
      };
    }
    case types.STORE_FILE_FAIL:
    case types.UPDATE_FILE_FAIL:
      return {
        ...state,
        errors: action.errors
      };
    case types.GET_CLIENTES_INIT:
      return {
        ...state,
        clientes: {...defaultClientes, loading: true},
        errors: null
      };
    case types.GET_CLIENTES_DONE: {
      const clientes = {
        data: [...action.clientes],
        loading: false
      };

      return {
        ...state,
        clientes
      };
    }
    case types.GET_CLIENTES_FAIL:
      return {
        ...state,
        clientes: {...state.clientes, loading: false},
        errors: action.errors
      };
    case types.GET_FROM_INIT:
      return {
        ...state,
        years: {...defaultYears, loading: true},
        errors: null
      };
    case types.GET_FROM_DONE: {
      const years = {
        data: [...action.years],
        loading: false
      };

      return {
        ...state,
        years
      };
    }
    case types.GET_FROM_FAIL:
      return {
        ...state,
        years: {...state.years, loading: false},
        errors: action.errors
      };
    case types.GET_MONTHS_INIT:
      return {
        ...state,
        months: {...defaultMonths, loading: true},
        errors: null
      };
    case types.GET_MONTHS_DONE: {
      const months = {
        data: [...action.months],
        loading: false
      };

      return {
        ...state,
        months
      };
    }
    case types.GET_MONTHS_FAIL:
      return {
        ...state,
        months: {...state.months, loading: false},
        errors: action.errors
      };
    default:
      return state;
  }
};
