import { default as BaseAlert } from 'react-bootstrap/Alert';
import styles from './Alert.module.scss';

const Alert = (props) => {
  const {
    children,
    ...rest
  } = props;

  return (
    <BaseAlert
      className={styles.Alert}
      {...rest}
    >
      {children}
    </BaseAlert>
  );
};

export default Alert;
