import * as types from '../actions/types/files-meses';

export const initialState = {
  months: [],
  isLoading: true,
  errors: null
};

export const reducer = (state, action) => {
  switch (action.type) {
    case types.GET_MONTHS_INIT:
      return {
        ...state,
        months: [],
        isLoading: true,
        errors: null
      };
    case types.GET_MONTHS_DONE: {
      const months = [... action.months];

      return {
        ...state,
        months,
        isLoading: false
      };
    }
    case types.GET_MONTHS_FAIL:
      return {
        ...state,
        isLoading: false,
        errors: action.errors
      };
    case types.ADD_TO_FILES_COUNT: {
      const months = state.months.map((month) => {
        if (month.id === action.id) {
          month.files_count += action.number;
        }

        return month;
      });

      return {
        ...state,
        months
      };
    }
    default:
      return state;
  }
};
