import classNames from 'classnames';
import { default as BaseDropdownToggle } from 'react-bootstrap/DropdownToggle';
import styles from './DropdownToggle.module.scss';

const DropdownToggle = (props) => {
  const {
    variant = 'primary',
    className,
    ...rest
  } = props;

  return (
    <BaseDropdownToggle
      className={classNames(className, {
        [styles.DropdownToggle]: variant === 'primary'
      })}
      {...rest}
    />
  );
};

export default DropdownToggle;
