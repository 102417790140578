export const GET_CONTADORES_INIT = 'GET_CONTADORES_INIT';
export const GET_CONTADORES_DONE = 'GET_CONTADORES_DONE';
export const GET_CONTADORES_FAIL = 'GET_CONTADORES_FAIL';

export const STORE_CONTADOR_INIT = 'STORE_CONTADOR_INIT';
export const STORE_CONTADOR_DONE = 'STORE_CONTADOR_DONE';
export const STORE_CONTADOR_FAIL = 'STORE_CONTADOR_FAIL';

export const UPDATE_CONTADOR_INIT = 'UPDATE_CONTADOR_INIT';
export const UPDATE_CONTADOR_DONE = 'UPDATE_CONTADOR_DONE';
export const UPDATE_CONTADOR_FAIL = 'UPDATE_CONTADOR_FAIL';

export const DELETE_CONTADOR_INIT = 'DELETE_CONTADOR_INIT';
export const DELETE_CONTADOR_DONE = 'DELETE_CONTADOR_DONE';
export const DELETE_CONTADOR_FAIL = 'DELETE_CONTADOR_FAIL';
