import classNames from 'classnames';
import FormControl from 'react-bootstrap/FormControl';
import styles from './Input.module.scss';

const Input = (props) => {
  const {
    className,
    ...rest
  } = props;

  return (
    <FormControl
      className={classNames(styles.Input, className)}
      {...rest}
    />
  );
};

export default Input;
