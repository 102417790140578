import Http from '../../../libs/Http';
import Storage from '../../../libs/Storage';
import getErrors from '../../../helpers/getErrors';
import * as creators from './creators/years';

const currentYear = new Date().getFullYear();

export const getFrom = (year = currentYear) => {
  return async (dispatch) => {
    dispatch(creators.getFromInit());

    try {
      const token = Storage.instance.get('token-mjAR4g');
      const response = await Http.instance.get(`years/${year}/from?token=${token}`);

      dispatch(creators.getFromDone(response.data));
    } catch (error) {
      dispatch(creators.getFromFail(getErrors(error)));
    }
  };
};

export const addToFilesCount = (id, number) =>
  (dispatch) => dispatch(creators.addToFilesCount(id, number));
