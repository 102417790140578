import * as types from '../actions/types/contadores';

const defaultPagination = {
  current_page: 0,
  per_page: 0,
  total: 0
};

export const initialState = {
  contadores: [],
  pagination: {...defaultPagination},
  isLoading: true,
  isSaved: false,
  isDeleting: false,
  errors: null
};

export const reducer = (state, action) => {
  switch (action.type) {
    case types.GET_CONTADORES_INIT:
      return {
        ...state,
        contadores: [],
        pagination: {...defaultPagination},
        isLoading: true,
        errors: null
      };
    case types.GET_CONTADORES_DONE: {
      const {
        data: contadores,
        current_page,
        per_page,
        total
      } = action.contadores;

      const pagination = {
        current_page,
        per_page,
        total
      };

      return {
        ...state,
        contadores,
        pagination,
        isLoading: false
      };
    }
    case types.GET_CONTADORES_FAIL:
      return {
        ...state,
        isLoading: false,
        errors: action.errors
      };
    case types.STORE_CONTADOR_INIT:
      return {
        ...state,
        isSaved: false,
        errors: null
      };
    case types.STORE_CONTADOR_DONE: {
      const [, contadores] = action.data;

      const {
        data,
        current_page,
        per_page,
        total
      } = contadores;

      const pagination = {
        current_page,
        per_page,
        total
      };

      return {
        ...state,
        contadores: [...data],
        pagination,
        isSaved: true
      };
    }
    case types.STORE_CONTADOR_FAIL:
      return {
        ...state,
        errors: action.errors
      };
    case types.UPDATE_CONTADOR_INIT:
      return {
        ...state,
        isSaved: false,
        errors: null
      };
    case types.UPDATE_CONTADOR_DONE: {
      const contadores = state.contadores.map((contador) => {
        if (contador.id === action.contador.id) {
          return action.contador;
        }

        return contador;
      });

      return {
        ...state,
        contadores,
        isSaved: true
      };
    }
    case types.UPDATE_CONTADOR_FAIL:
      return {
        ...state,
        errors: action.errors
      };
    case types.DELETE_CONTADOR_INIT:
      return {
        ...state,
        isDeleting: true,
        errors: null
      };
    case types.DELETE_CONTADOR_DONE: {
      const contadores = state.contadores.filter((contador) => contador.id !== action.contador.id);

      return {
        ...state,
        contadores,
        isDeleting: false
      };
    }
    case types.DELETE_CONTADOR_FAIL:
      return {
        ...state,
        isDeleting: false,
        errors: action.errors
      };
    default:
      return state;
  }
};
