import PropTypes from 'prop-types';
import Modal from '../Modal';
import Button from '../Button';

const Confirm = (props) => {
  const {
    show = false,
    title,
    content,
    loading = false,
    loadingText,
    cancelButton,
    confirmButton,
    onCancel,
    onConfirm
  } = props;

  return (
    <Modal
      show={show}
      onHide={onCancel}
    >
      <Modal.Header>
        {title}
      </Modal.Header>
      <Modal.Body>
        {content}
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="link"
          disabled={loading}
          onClick={onCancel}
        >
          {cancelButton}
        </Button>
        <Button
          disabled={loading}
          onClick={onConfirm}
        >
          {loading ? loadingText : confirmButton}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

Confirm.propTypes = {
  show: PropTypes.bool,
  title: PropTypes.string,
  content: PropTypes.string,
  loading: PropTypes.bool,
  loadingText: PropTypes.string,
  cancelButton: PropTypes.string,
  confirmButton: PropTypes.string,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func
};

export default Confirm;
