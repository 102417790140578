import { useState } from 'react';
import PropTypes from 'prop-types';
import Search from '../../UI/Search';
import Button from '../../UI/Button';

const Header = (props) => {
  const [search, setSearch] = useState('');

  const {
    buttonText,
    onNew,
    placeholder,
    ...rest
  } = props;

  const onChange = (value) => {
    setSearch(value);
  };

  const onSearch = () => {
    rest.onSearch(search);
  };

  return (
    <div className="bg-white d-flex align-items-center p-4">
      <div className="flex-grow-1 me-5">
        <Search
          onChange={onChange}
          onSearch={onSearch}
          placeholder={placeholder}
          value={search}
        />
      </div>
      <div>
        <Button onClick={onNew}>
          {buttonText}
        </Button>
      </div>
    </div>
  );
};

Header.propTypes = {
  buttonText: PropTypes.string,
  onNew: PropTypes.func,
  onSearch: PropTypes.func,
  placeholder: PropTypes.string
};

export default Header;
