import FormSelect from 'react-bootstrap/FormSelect';
import styles from './Select.module.scss';

const Select = (props) => {
  const {
    children,
    ...rest
  } = props;

  return (
    <FormSelect
      className={styles.Select}
      {...rest}
    >
      {children}
    </FormSelect>
  );
};

export default Select;
