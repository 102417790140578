import classNames from 'classnames';
import ListGroup from 'react-bootstrap/ListGroup';
import ListGroupItem from 'react-bootstrap/ListGroupItem';
import styles from './List.module.scss';

const List = (props) => {
  const {
    className,
    children,
    ...rest
  } = props;

  return (
    <ListGroup
      className={classNames(styles.List, className)}
      {...rest}
    >
      {children}
    </ListGroup>
  );
};

const Item = (props) => {
  const {
    children,
    ...rest
  } = props;

  return (
    <ListGroupItem {...rest}>
      {children}
    </ListGroupItem>
  );
};

List.Item = Item;

export default List;
