import Http from '../../../libs/Http';
import Storage from '../../../libs/Storage';
import objectToFormData from '../../../helpers/objectToFormData';
import getErrors from '../../../helpers/getErrors';
import * as creators from './creators/files';

export const getFiles = (userId, yearId, monthId, params = {}) => {
  return async (dispatch) => {
    dispatch(creators.getFilesInit());

    try {
      const token = Storage.instance.get('token-mjAR4g');
      const response = await Http.instance.get(`files/${userId}/${yearId}/${monthId}?token=${token}`, { params });

      dispatch(creators.getFilesDone(response.data));
    } catch (error) {
      dispatch(creators.getFilesFail(getErrors(error)));
    }
  };
};

export const storeFile = (file) => {
  return async (dispatch) => {
    dispatch(creators.storeFileInit());

    try {
      const token = Storage.instance.get('token-mjAR4g');
      const response = await Http.instance.post(`files?token=${token}`, objectToFormData(file));

      dispatch(creators.storeFileDone(response.data));
      return true;
    } catch (error) {
      dispatch(creators.storeFileFail(getErrors(error)));
      return false;
    }
  };
};

export const updateFile = (file) => {
  return async (dispatch) => {
    dispatch(creators.updateFileInit());

    try {
      const token = Storage.instance.get('token-mjAR4g');
      const response = await Http.instance.post(`files/${file.id}?token=${token}`, objectToFormData(file));

      dispatch(creators.updateFileDone(response.data));
      return true;
    } catch (error) {
      dispatch(creators.updateFileFail(getErrors(error)));
      return false;
    }
  };
};

export const deleteFile = (id) => {
  return async (dispatch) => {
    dispatch(creators.deleteFileInit());

    try {
      const token = Storage.instance.get('token-mjAR4g');
      const response = await Http.instance.delete(`files/${id}?token=${token}`);

      dispatch(creators.deleteFileDone(response.data));
      return true;
    } catch (error) {
      dispatch(creators.deleteFileFail(getErrors(error)));
      return false;
    }
  };
};

export const getClientes = (params) => {
  return async (dispatch) => {
    dispatch(creators.getClientesInit());

    try {
      const token = Storage.instance.get('token-mjAR4g');
      const response = await Http.instance.get(`files/clientes?token=${token}`, { params });

      dispatch(creators.getClientesDone(response.data));
    } catch (error) {
      dispatch(creators.getClientesFail(getErrors(error)));
    }
  };
};

export const getYears = (userId) => {
  return async (dispatch) => {
    dispatch(creators.getYearsInit());

    try {
      const token = Storage.instance.get('token-mjAR4g');
      const response = await Http.instance.get(`files/${userId}/years?token=${token}`);

      dispatch(creators.getYearsDone(response.data));
    } catch (error) {
      dispatch(creators.getYearsFail(getErrors(error)));
    }
  };
};

export const getMonths = (userId, yearId) => {
  return async (dispatch) => {
    dispatch(creators.getMothsInit());

    try {
      const token = Storage.instance.get('token-mjAR4g');
      const response = await Http.instance.get(`files/${userId}/${yearId}/months?token=${token}`);

      dispatch(creators.getMothsDone(response.data));
    } catch (error) {
      dispatch(creators.getMothsFail(getErrors(error)));
    }
  };
};

export const addFileToFiles = (file) =>
  (dispatch) => dispatch(creators.addFileToFiles(file));

  export const removeFileFromFiles = (id) =>
    (dispatch) => dispatch(creators.removeFileFromFiles(id));
