import { createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';
import Http from '../../libs/Http';
import Storage from '../../libs/Storage';
import getErrors from '../../helpers/getErrors';
import * as backend from '../../constants/backend';

const initialState = {
  token: null,
  user: null,
  isVerifying: true,
  isUpdating: false,
  isUpdated: false,
  errors: null
};

export const login = createAsyncThunk('login', async (credentials, { rejectWithValue }) => {
  try {
    const response = await Http.instance.post('login', credentials);
    const { token, user, expiresIn } = response.data;
    const expiresOn = new Date(new Date().getTime() + expiresIn * 1000);

    Storage.instance.set('token-mjAR4g', token);
    Storage.instance.set('user-mjAR4g', JSON.stringify(user));
    Storage.instance.set('expiresOn-mjAR4g', expiresOn.toString());

    return {token, user};
  } catch (error) {
    return rejectWithValue(getErrors(error));
  }
});

export const logout = createAsyncThunk('logout', async () => {
  try {
    const token = Storage.instance.get('token-mjAR4g');

    Storage.instance.remove('token-mjAR4g');
    Storage.instance.remove('user-mjAR4g');
    Storage.instance.remove('expiresOn-mjAR4g');

    token && await Http.instance.post(`logout?token=${token}`);
  } catch (error) { }
});

export const updateProfile = createAsyncThunk('updateProfile', async (user, { rejectWithValue }) => {
    try {
      const token = Storage.instance.get('token-mjAR4g');
      const response = await Http.instance.put(`users/profile?token=${token}`, user);
      const data = response.data;

      Storage.instance.set('user-mjAR4g', JSON.stringify(data));

      return { user: data };
    } catch (error) {
      return rejectWithValue(getErrors(error));
    }
});

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    toPersist: (state, action) => {
      state.token = action.payload.token;
      state.user = action.payload.user;
      state.isVerifying = false;
    },
    leaveProfile: (state) => {
      state.isUpdated = false;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.errors = null;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.token = action.payload.token;
        state.user = action.payload.user;
      })
      .addCase(login.rejected, (state, action) => {
        state.errors = action.payload;
      })
      .addCase(updateProfile.pending, (state) => {
        state.isUpdating = true;
        state.isUpdated = false;
      })
      .addCase(updateProfile.fulfilled, (state, action) => {
        state.user = action.payload.user;
        state.isUpdating = false;
        state.isUpdated = true;
      })
      .addCase(updateProfile.rejected, (state, action) => {
        state.isUpdating = false;
        state.errors = action.payload;
      })
      .addMatcher(isAnyOf(logout.fulfilled, logout.rejected), (state) => {
        state.token = null;
        state.user = null;
        state.isVerifying = false;
      });
  }
});

const actions = authSlice.actions;

const isAuthenticationExpired = () => {
  const expiresOn = new Date(Storage.instance.get('expiresOn-mjAR4g'));

  return expiresOn <= new Date();
};

export const checkIfUserIsAuthenticated = () => {
  return (dispatch) => {
    let token = Cookies.get('token-mjAR4g');
    let user = Cookies.get('user-mjAR4g');
    let expiresOn = Cookies.get('expiresOn-mjAR4g');

    token && Storage.instance.set('token-mjAR4g', token);
    user && Storage.instance.set('user-mjAR4g', user);
    expiresOn && Storage.instance.set('expiresOn-mjAR4g', expiresOn);

    Cookies.remove('token-mjAR4g', { domain: `.${backend.DOMAIN}`, path: '' });
    Cookies.remove('user-mjAR4g', { domain: `.${backend.DOMAIN}`, path: '' });
    Cookies.remove('expiresOn-mjAR4g', { domain: `.${backend.DOMAIN}`, path: '' });

    token = Storage.instance.get('token-mjAR4g');
    user = JSON.parse(Storage.instance.get('user-mjAR4g'));

    (!token || isAuthenticationExpired())
      ? dispatch(logout())
      : dispatch(actions.toPersist({token, user}));
  };
};

export const leaveProfile = () =>
  (dispatch) => dispatch(actions.leaveProfile());

export default authSlice.reducer;
