import * as types from '../types/clientes';

export const getClientesInit = () => ({
  type: types.GET_CLIENTES_INIT
});

export const getClientesDone = (clientes) => ({
  type: types.GET_CLIENTES_DONE,
  clientes
});

export const getClientesFail = (errors) => ({
  type: types.GET_CLIENTES_FAIL,
  errors
});

export const storeClienteInit = () => ({
  type: types.STORE_CLIENTE_INIT
});

export const storeClienteDone = (data) => ({
  type: types.STORE_CLIENTE_DONE,
  data
});

export const storeClienteFail = (errors) => ({
  type: types.STORE_CLIENTE_FAIL,
  errors
});

export const updateClienteInit = () => ({
  type: types.UPDATE_CLIENTE_INIT
});

export const updateClienteDone = (cliente) => ({
  type: types.UPDATE_CLIENTE_DONE,
  cliente
});

export const updateClienteFail = (errors) => ({
  type: types.UPDATE_CLIENTE_FAIL,
  errors
});

export const deleteClienteInit = () => ({
  type: types.DELETE_CLIENTE_INIT
});

export const deleteClienteDone = (cliente) => ({
  type: types.DELETE_CLIENTE_DONE,
  cliente
});

export const deleteClienteFail = (errors) => ({
  type: types.DELETE_CLIENTE_FAIL,
  errors
});

export const addToFilesCount = (id, number) => ({
  type: types.ADD_TO_FILES_COUNT,
  id,
  number
});
