import { Link } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Folder } from 'react-bootstrap-icons';
import useFilesClientes from '../../../hooks/useFilesClientes';
import Table from '../../../components/UI/Table';
import Paginator from '../../../components/UI/Paginator';

const Clientes = () => {
  const {
    clientes,
    pagination,
    isLoading,
    getLinkState,
    onNextOrPrevClientes
  } = useFilesClientes();

  return (
    <>
      <Row>
        <Col>
          <div className="bg-white">
            <Table>
              <thead>
                <tr>
                  <th>Cliente</th>
                  <th>Total de archivos</th>
                </tr>
              </thead>
              <tbody>
                {isLoading ? (
                  <tr>
                    <td
                      colSpan={2}
                      className="text-center"
                    >
                      Cargando...
                    </td>
                  </tr>
                ) : null}
                {clientes.map((cliente) => (
                  <tr key={cliente.id}>
                    <td>
                      <Link
                        to={`${cliente.id}/anios`}
                        state={getLinkState(cliente)}
                      >
                        <Folder />
                        {cliente.full_name}
                      </Link>
                    </td>
                    <td>{cliente.files_count}</td>
                  </tr>
                ))}
                {(!isLoading && !clientes.length) ? (
                  <tr>
                    <td
                      colSpan={2}
                      className="text-center"
                    >
                      No se han encontrado resultados.
                    </td>
                  </tr>
                ) : null}
              </tbody>
            </Table>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <Paginator
            className="mb-0"
            currentPage={pagination.current_page}
            perPage={pagination.per_page}
            total={pagination.total}
            onPageChange={onNextOrPrevClientes}
          />
        </Col>
      </Row>
    </>
  );
};

export default Clientes;
