import { useState, useEffect } from 'react';
import { useLocation, useMatch, useNavigate } from 'react-router-dom';
import useAuth from './useAuth';

export default function useFiles() {
  const [file, setStoredFile] = useState(null);
  const [search, setSearch] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [isShowUpload, setIsShowUpload] = useState(false);
  const [isUploadedSuccessfully, setIsUploadedSuccessfully] = useState(false);
  const [isDeletedSuccessfully, setIsDeletedSuccessfully] = useState(false);
  const location = useLocation();
  const matchAnyFilesRoute = useMatch('/archivos/:userId/*');
  const matchExactFilesRoute = useMatch('/archivos/:userId/archivos');
  const matchClienteExcatFilesRoute = useMatch('/archivos/archivos');
  const navigate = useNavigate();
  const { user } = useAuth();

  useEffect(() => {
    resetSearch();
  }, [location]);

  const isArchivo = Boolean(matchAnyFilesRoute);

  const resetSearch = () => {
    if (!matchClienteExcatFilesRoute && !matchExactFilesRoute) {
      setSearch('');
    }
  };

  const onShowUpload = () => {
    setStoredFile(null);
    setIsShowUpload(true);
    onHideUploadedSuccessfully();
    onHideDeletedSuccessfully();
  };

  const onHideUpload = () => {
    setSelectedFile(null);
    setIsShowUpload(false);
  };

  const onShowUploadedSuccessfully = (file) => {
    setIsUploadedSuccessfully(true);
    setIsDeletedSuccessfully(false);
    setStoredFile(file);
  };

  const onHideUploadedSuccessfully = () => {
    setIsUploadedSuccessfully(false);
  };

  const onSelectFile = (file) => {
    setSelectedFile(file);
    onShowUpload();
  };

  const onShowDeletedSuccessfully = () => {
    setIsUploadedSuccessfully(false);
    setIsDeletedSuccessfully(true);
  };

  const onHideDeletedSuccessfully = () => {
    setIsDeletedSuccessfully(false);
  };

  const onSearch = (search) => {
    setSearch(search);

    if (user?.is_cliente) {
      navigate(`/archivos/archivos`);
      return;
    }

    if (isArchivo && !matchExactFilesRoute) {
      const state = location.state;
      const cliente = state?.menu?.[0];

      navigate(`/archivos/${state?.userId}/archivos`, {
        state: {
          ...state,
          menu: [{
            id: cliente?.id,
            title: cliente?.title,
            path: `${cliente?.id}/anios`
          }]
        }
      });
    }
  };

  return {
    file,
    user,
    search,
    selectedFile,
    isArchivo,
    isShowUpload,
    isUploadedSuccessfully,
    isDeletedSuccessfully,
    onShowUpload,
    onHideUpload,
    onShowUploadedSuccessfully,
    onHideUploadedSuccessfully,
    onSelectFile,
    onShowDeletedSuccessfully,
    onHideDeletedSuccessfully,
    onSearch
  };
}
