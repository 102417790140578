import { useState, useEffect, useReducer } from 'react';
import { initialState, reducer } from '../store/local/reducers/contadores';
import * as actions from '../store/local/actions/contadores';

export default function useContadores() {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const [selectedContador, setSelectedContador] = useState(null);
  const [selectedContadorId, setSelectedContadorId] = useState(null);
  const [isShowNewOrEdit, setIsShowNewOrEdit] = useState(false);
  const [isShowSavedMessage, setIsShowSavedMessage] = useState(false);
  const [isShowConfirmDelete, setIsShowConfirmDelete] = useState(false);
  const [isShowDeletedMessage, setIsShowDeletedMessage] = useState(false);

  useEffect(() => {
    getContadores({ page, search });
  }, [page, search]);

  useEffect(() => {
    showOrHideSavedMessage();
  }, [state.isSaved]);

  const getContadores = (params = {}) => {
    actions.getContadores(params)(dispatch);
  };

  const showOrHideSavedMessage = () => {
    setIsShowSavedMessage(state.isSaved);
  };

  const onSearchContadores = (search) => {
    setPage(1);
    setSearch(search);
  };

  const onShowNew = () => {
    setIsShowNewOrEdit(true);
  };

  const onHideNewOrEdit = () => {
    setSelectedContador(null);
    setIsShowNewOrEdit(false);
  };

  const onSaveContador = async (contador) => {
    setIsShowSavedMessage(false);
    setIsShowDeletedMessage(false);

    !selectedContador && await actions.storeContador(contador, { page })(dispatch);
    selectedContador && await actions.updateContador(contador)(dispatch);
  };

  const onHideSavedMessage = () => {
    setIsShowSavedMessage(false);
  };

  const onEditContador = (contador) => () => {
    setSelectedContador(contador);
    setIsShowNewOrEdit(true);
  };

  const onNextOrPrevContadores = (page) => {
    setPage(page);
  };

  const onShowConfirmDelete = (id) => () => {
    setSelectedContadorId(id);
    setIsShowConfirmDelete(true);
  };

  const onCancelConfirmDelete = () => {
    setSelectedContadorId(null);
    setIsShowConfirmDelete(false);
  };

  const onDeleteContador = async () => {
    setIsShowSavedMessage(false);
    setIsShowDeletedMessage(false);

    if (await actions.deleteContador(selectedContadorId)(dispatch)) {
      setIsShowDeletedMessage(true);
      onCancelConfirmDelete();
    }
  };

  const onHideDeletedMessage = () => {
    setIsShowDeletedMessage(false);
  };

  return {
    contadores: state.contadores,
    pagination: state.pagination,
    isLoading: state.isLoading,
    isSaved: state.isSaved,
    isDeleting: state.isDeleting,
    selectedContador,
    isShowNewOrEdit,
    isShowSavedMessage,
    isShowConfirmDelete,
    isShowDeletedMessage,
    onSearchContadores,
    onShowNew,
    onHideNewOrEdit,
    onSaveContador,
    onHideSavedMessage,
    onEditContador,
    onNextOrPrevContadores,
    onShowConfirmDelete,
    onCancelConfirmDelete,
    onDeleteContador,
    onHideDeletedMessage
  };
}
