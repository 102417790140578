import * as types from '../types/years';

export const getFromInit = () => ({
  type: types.GET_FROM_INIT
});

export const getFromDone = (years) => ({
  type: types.GET_FROM_DONE,
  years
});

export const getFromFail = (errors) => ({
  type: types.GET_FROM_FAIL,
  errors
});

export const addToFilesCount = (id, number) => ({
  type: types.ADD_TO_FILES_COUNT,
  id,
  number
});
