import { default as BaseDropdownMenu } from 'react-bootstrap/DropdownMenu';
import styles from './DropdownMenu.module.scss';

const DropdownMenu = (props) => {
  const {
    children,
    ...rest
  } = props;

  return (
    <BaseDropdownMenu
      className={styles.DropdownMenu}
      {...rest}
    >
      {children}
    </BaseDropdownMenu>
  );
};

export default DropdownMenu;
