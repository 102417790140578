import * as types from '../types/months';

export const getMonthsInit = () => ({
  type: types.GET_MONTHS_INIT
});

export const getMonthsDone = (months) => ({
  type: types.GET_MONTHS_DONE,
  months
});

export const getMonthsFail = (errors) => ({
  type: types.GET_MONTHS_FAIL,
  errors
});

export const addToFilesCount = (id, number) => ({
  type: types.ADD_TO_FILES_COUNT,
  id,
  number
});
