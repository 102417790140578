import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Form from 'react-bootstrap/Form';
import Modal from '../../UI/Modal';
import Input from '../../UI/Input';
import Button from '../../UI/Button';

const UserSchema = Yup.object().shape({
  full_name: Yup.string().required('El campo "Nombre completo" es obligatorio.'),
  username: Yup.string().required('El campo "Usuario" es obligatorio.'),
  password: Yup.string().when('id', {
    is: (value) => !value,
    then: Yup.string().required('El campo "Contraseña" es obligatorio.')
  }),
  password_confirmation: Yup.string().when('id', {
    is: (value) => !value,
    then: Yup.string().required('Vuelva a escribir su contraseña.')
      .oneOf([Yup.ref('password'), null], 'Las contraseñas no coinciden.'),
    otherwise: Yup.string().when('password', {
      is: (value) => !!value,
      then: Yup.string().required('Vuelva a escribir su contraseña.')
        .oneOf([Yup.ref('password'), null], 'Las contraseñas no coinciden.')
    })
  })
});

const NewOrEdit = (props) => {
  const formik = useFormik({
    initialValues: {
      full_name: '',
      username: '',
      password: '',
      password_confirmation: ''
    },
    validationSchema: UserSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      await save(values);
      setSubmitting(false);
    }
  });

  const {
    user,
    isShow,
    isSaved,
    ...rest
  } = props;

  useEffect(() => {
    saved();
  }, [isSaved]);

  useEffect(() => {
    setValues();
  }, [user]);

  const saved = () => {
    if (isSaved) {
      onCancel();
    }
  };

  const save = async (user) => {
    await rest.onSave(user);
  };

  const setValues = () => {
    if (user) {
      user.password = '';
      user.password_confirmation = '';
      formik.setValues(user);
    }
  };

  const onCancel = () => {
    formik.resetForm();
    rest.onCancel();
  };

  return (
    <Modal
      show={isShow}
      onHide={onCancel}
    >
      <Modal.Header>
        Nuevo cliente
      </Modal.Header>
      <Modal.Body>
        <Form noValidate>
          <Form.Group
            className="mb-3"
            controlId="full_name"
          >
            <Form.Label>Nombre completo</Form.Label>
            <Input
              type="text"
              name="full_name"
              isInvalid={!!formik.errors.full_name && formik.touched.full_name}
              maxLength={150}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.full_name}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.full_name}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group
            className="mb-3"
            controlId="username"
          >
            <Form.Label>Usuario</Form.Label>
            <Input
              type="text"
              name="username"
              isInvalid={!!formik.errors.username && formik.touched.username}
              maxLength={50}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.username}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.username}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group
            className="mb-3"
            controlId="password"
          >
            <Form.Label>Contraseña</Form.Label>
            <Input
              type="password"
              name="password"
              isInvalid={!!formik.errors.password && formik.touched.password}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.password}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.password}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group
            className="mb-3"
            controlId="password_confirmation"
          >
            <Form.Label>Confirmar contraseña</Form.Label>
            <Input
              type="password"
              name="password_confirmation"
              isInvalid={!!formik.errors.password_confirmation && formik.touched.password_confirmation}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.password_confirmation}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.password_confirmation}
            </Form.Control.Feedback>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="link"
          disabled={formik.isSubmitting}
          onClick={onCancel}
        >
          Cancelar
        </Button>
        <Button
          disabled={formik.isSubmitting}
          onClick={formik.handleSubmit}
        >
          {formik.isSubmitting ? 'Guardando...' : 'Guardar'}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

NewOrEdit.propTypes = {
  user: PropTypes.object,
  isShow: PropTypes.bool,
  isSaved: PropTypes.bool,
  onCancel: PropTypes.func,
  onSave: PropTypes.func
};

export default NewOrEdit;
