import * as types from '../actions/types/clientes';

const defaultPagination = {
  current_page: 0,
  per_page: 0,
  total: 0
};

export const initialState = {
  clientes: [],
  pagination: {...defaultPagination},
  isLoading: true,
  isSaved: false,
  isDeleting: false,
  errors: null
};

export const reducer = (state, action) => {
  switch (action.type) {
    case types.GET_CLIENTES_INIT:
      return {
        ...state,
        clientes: [],
        pagination: {...defaultPagination},
        isLoading: true,
        errors: null
      };
    case types.GET_CLIENTES_DONE: {
      const {
        data: clientes,
        current_page,
        per_page,
        total
      } = action.clientes;

      const pagination = {
        current_page,
        per_page,
        total
      };

      return {
        ...state,
        clientes,
        pagination,
        isLoading: false
      };
    }
    case types.GET_CLIENTES_FAIL:
      return {
        ...state,
        isLoading: false,
        errors: action.errors
      };
    case types.STORE_CLIENTE_INIT:
      return {
        ...state,
        isSaved: false,
        errors: null
      };
    case types.STORE_CLIENTE_DONE: {
      const [, clientes] = action.data;

      const {
        data,
        current_page,
        per_page,
        total
      } = clientes;

      const pagination = {
        current_page,
        per_page,
        total
      };

      return {
        ...state,
        clientes: [...data],
        pagination,
        isSaved: true
      };
    }
    case types.STORE_CLIENTE_FAIL:
      return {
        ...state,
        errors: action.errors
      };
    case types.UPDATE_CLIENTE_INIT:
      return {
        ...state,
        isSaved: false,
        errors: null
      };
    case types.UPDATE_CLIENTE_DONE: {
      const clientes = state.clientes.map((cliente) => {
        if (cliente.id === action.cliente.id) {
          return action.cliente;
        }

        return cliente;
      });

      return {
        ...state,
        clientes,
        isSaved: true
      };
    }
    case types.UPDATE_CLIENTE_FAIL:
      return {
        ...state,
        errors: action.errors
      };
    case types.DELETE_CLIENTE_INIT:
      return {
        ...state,
        isDeleting: true,
        errors: null
      };
    case types.DELETE_CLIENTE_DONE: {
      const clientes = state.clientes.filter((cliente) => cliente.id !== action.cliente.id);

      return {
        ...state,
        clientes,
        isDeleting: false
      };
    }
    case types.DELETE_CLIENTE_FAIL:
      return {
        ...state,
        isDeleting: false,
        errors: action.errors
      };
    default:
      return state;
  }
};
