import { Link, useLocation } from 'react-router-dom';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import styles from './Menu.module.scss';

const Menu = () => {
  const location = useLocation();
  const state = location.state;

  const menu = (state?.menu || [])
    .filter(Boolean)
    .map((item, index, menu) => ({
      ...item,
      active: (index === (menu.length - 1))
    }));

  return (
    <Breadcrumb className={styles.Menu}>
      <Breadcrumb.Item
        linkAs={Link}
        linkProps={{
          to: '/archivos'
        }}
        className={styles.Item}
        active={!menu.length}
      >
        Archivos
      </Breadcrumb.Item>
      {menu.map((item, index) => (
        <Breadcrumb.Item
          key={item.title}
          linkAs={Link}
          linkProps={{
            to: item.path || '',
            state: {
              ...state,
              menu: menu.slice(0, (index + 1))
            }
          }}
          className={styles.Item}
          active={item.active}
        >
          {item.title}
        </Breadcrumb.Item>
      ))}
    </Breadcrumb>
  );
};

export default Menu;
