import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Title from '../../components/Title';

const Home = () => {
  return (
    <Row>
      <Col>
        <Title>Sistema de Administración de Informes Mensuales.</Title>
      </Col>
    </Row>
  );
};

export default Home;
