import Cookies from 'js-cookie';
import * as backend from '../../constants/backend';

const Login = () => {
  if (!Cookies.get('token-mjAR4g')) {
    window.location.replace(`${backend.PROTOCOL}${backend.DOMAIN}`);
  }

  return 'Cargando...';
};

export default Login;
