import { useState, useEffect, useReducer, useContext } from 'react';
import { useMatch, useParams } from 'react-router-dom';
import FilesContext from '../contexts/FilesContext';
import useAuth from '../hooks/useAuth';
import { initialState, reducer } from '../store/local/reducers/files-archivos';
import * as actions from '../store/local/actions/files-archivos';

export default function useFilesArchivos() {
  const [isShowDelete, setIsShowDelete] = useState(false);
  const [selectedFileId, setSelectedFileId] = useState(null);
  const [state, dispatch] = useReducer(reducer, initialState);
  const matchExactFilesRoute = useMatch('/archivos/:userId/archivos');
  const { user } = useAuth();

  const {
    search,
    isUploadedSuccessfully,
    ...context
  } = useContext(FilesContext);

  const {
    userId = 0,
    yearId = 0,
    monthId = 0
  } = useParams();

  useEffect(() => {
    getFiles();
  }, [search]);

  useEffect(() => {
    if (isUploadedSuccessfully) {
      addFileToFiles();
    }
  }, [isUploadedSuccessfully]);

  const canUpdateOrDeleteFile = () => {
    return !user?.is_cliente;
  };

  const getFiles = () => {
    actions.getFiles(userId, yearId, monthId, { search })(dispatch);
  };

  const addFileToFiles = () => {
    const {
      id,
      user_id,
      year_id,
      month_id
    } = context.file;

    const isSameUserId = +user_id === +userId;
    const isSameYearId = +year_id === +yearId;
    const isSameMonthId = +month_id === +monthId;

    if (isSameUserId && matchExactFilesRoute) {
      actions.addFileToFiles(context.file)(dispatch);
      return;
    }

    // Remueve el archivo si no pertenece al mismo cliente o año o mes.
    if (!isSameUserId || !isSameYearId || !isSameMonthId) {
      actions.removeFileFromFiles(id)(dispatch);
      return;
    }

    actions.addFileToFiles(context.file)(dispatch);
  };

  const onSelectFile = (file) => () => {
    context.onSelectFile(file);
  };

  const onShowDelete = (id) => () => {
    setIsShowDelete(true);
    setSelectedFileId(id);
  };

  const onCancelDelete = () => {
    setIsShowDelete(false);
    setSelectedFileId(null);
  };

  const onDeleteFile = async () => {
    if (await actions.deleteFile(selectedFileId)(dispatch)) {
      onCancelDelete();
      context.onShowDeletedSuccessfully();
    };
  };

  return {
    files: state.files,
    isLoading: state.isLoading,
    isDeleting: state.isDeleting,
    isShowDelete,
    canUpdateOrDeleteFile,
    onSelectFile,
    onShowDelete,
    onCancelDelete,
    onDeleteFile
  };
}
