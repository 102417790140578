import { useEffect, useReducer, useContext } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import FilesContext from '../contexts/FilesContext';
import { initialState, reducer } from '../store/local/reducers/files-meses';
import * as actions from '../store/local/actions/files-meses';

export default function useFilesMeses() {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { file, isUploadedSuccessfully } = useContext(FilesContext);
  const location = useLocation();
  const { userId = 0, yearId } = useParams();

  useEffect(() => {
    getMonths();
  }, [userId, yearId]);

  useEffect(() => {
    if (isUploadedSuccessfully) {
      addToFilesCount();
    }
  }, [isUploadedSuccessfully]);

  const hasMonths = state.months.length > 0;

  const getMonths = () => {
    actions.getMonths(userId, yearId)(dispatch);
  };

  const getLinkState = (month) => {
    const cliente = location.state?.menu?.[0];
    const year = location.state?.menu?.[1];

    return {
      ...location.state,
      menu: [
        cliente,
        year,
        {
          id: month.id,
          title: month.name,
          active: true
        }
      ]
    };
  };

  const addToFilesCount = () => {
    const monthId = +file.month_id;

    if (+file.user_id !== +userId || +file.year_id !== +yearId) {
      return;
    }

    actions.addToFilesCount(monthId, 1)(dispatch);
  };

  return {
    months: state.months,
    isLoading: state.isLoading,
    hasMonths,
    getLinkState
  };
}
