import * as types from '../types/contadores';

export const getContadoresInit = () => ({
  type: types.GET_CONTADORES_INIT
});

export const getContadoresDone = (contadores) => ({
  type: types.GET_CONTADORES_DONE,
  contadores
});

export const getContadoresFail = (errors) => ({
  type: types.GET_CONTADORES_FAIL,
  errors
});

export const storeContadorInit = () => ({
  type: types.STORE_CONTADOR_INIT
});

export const storeContadorDone = (data) => ({
  type: types.STORE_CONTADOR_DONE,
  data
});

export const storeContadorFail = (errors) => ({
  type: types.STORE_CONTADOR_FAIL,
  errors
});

export const updateContadorInit = () => ({
  type: types.UPDATE_CONTADOR_INIT
});

export const updateContadorDone = (contador) => ({
  type: types.UPDATE_CONTADOR_DONE,
  contador
});

export const updateContadorFail = (errors) => ({
  type: types.UPDATE_CONTADOR_FAIL,
  errors
});

export const deleteContadorInit = () => ({
  type: types.DELETE_CONTADOR_INIT
});

export const deleteContadorDone = (contador) => ({
  type: types.DELETE_CONTADOR_DONE,
  contador
});

export const deleteContadorFail = (errors) => ({
  type: types.DELETE_CONTADOR_FAIL,
  errors
});
