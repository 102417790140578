import { Fragment } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownMenu from '../../../components/UI/DropdownMenu';
import DropdownItem from '../../../components/UI/DropdownItem';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import { Folder, Pencil, Trash } from 'react-bootstrap-icons';
import useFilesArchivos from '../../../hooks/useFilesArchivos';
import Table from '../../../components/UI/Table';
import Button from '../../../components/UI/Button';
import DropdownToggle from '../../../components/UI/DropdownToggle';
import Confirm from '../../../components/UI/Confirm';

const Archivos = () => {
  const {
    files,
    isLoading,
    isDeleting,
    isShowDelete,
    canUpdateOrDeleteFile,
    onSelectFile,
    onShowDelete,
    onCancelDelete,
    onDeleteFile
  } = useFilesArchivos();

  return (
    <Fragment>
      <Row>
        <Col>
          <div className="bg-white">
            <Table>
              <thead>
                <tr>
                  <th>Título</th>
                  <th>Total de archivos</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {isLoading ? (
                  <tr>
                    <td
                      colSpan={3}
                      className="text-center"
                    >
                      Cargando...
                    </td>
                  </tr>
                ) : null}
                {files.map((file) => (
                  <tr key={file.id}>
                    <td>
                      <Folder /> {file.title}
                    </td>
                    <td>{file.size}</td>
                    <td className="text-end">
                      <Dropdown
                        as={ButtonGroup}
                        align="end"
                      >
                        <Button
                          as="a"
                          variant="primary"
                          href={file.url}
                          target="_blank"
                        >
                          Descargar
                        </Button>
                        {canUpdateOrDeleteFile() ? (
                          <Fragment>
                            <DropdownToggle split variant="primary" />
                            <DropdownMenu>
                              <DropdownItem onClick={onSelectFile(file)}>
                                <Pencil /> Editar
                              </DropdownItem>
                              <DropdownItem onClick={onShowDelete(file.id)}>
                                <Trash /> Eliminar
                              </DropdownItem>
                            </DropdownMenu>
                          </Fragment>
                        ) : null}
                      </Dropdown>
                    </td>
                  </tr>
                ))}
                {(!isLoading && !files.length) ? (
                  <tr>
                    <td
                      colSpan={3}
                      className="text-center"
                    >
                      No se han encontrado resultados.
                    </td>
                  </tr>
                ) : null}
              </tbody>
            </Table>
          </div>
        </Col>
      </Row>
      <Confirm
        show={isShowDelete}
        title="¿Eliminar archivo?"
        content="El archivo será eliminado permanentemente."
        loading={isDeleting}
        loadingText="Eliminando..."
        cancelButton="No, cancelar"
        confirmButton="Sí, eliminar"
        onCancel={onCancelDelete}
        onConfirm={onDeleteFile}
      />
    </Fragment>
  );
};

export default Archivos;
