import { Fragment, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import FormControl from 'react-bootstrap/FormControl';
import styles from './File.module.scss';

const File = (props) => {
  const draggableRef = useRef(null);
  const inputRef = useRef(null);

  const {
    name,
    isInvalid,
    ...rest
  } = props;

  useEffect(() => {
    draggableRef?.current?.addEventListener?.('dragenter', dragEnter);
    draggableRef?.current?.addEventListener?.('dragover', dragOver);
    draggableRef?.current?.addEventListener?.('dragleave', dragLeave);
    draggableRef?.current?.addEventListener?.('drop', drop);

    return () => {
      draggableRef?.current?.removeEventListener?.('dragenter', dragEnter);
      draggableRef?.current?.removeEventListener?.('dragover', dragOver);
      draggableRef?.current?.removeEventListener?.('dragleave', dragLeave);
      draggableRef?.current?.removeEventListener?.('drop', drop);
    };
  }, []);

  const dragEnter = (event) => {
    event.preventDefault();
    event.stopPropagation();
    event.target.classList.add(styles.DragOver);
  };

  const dragOver = (event) => {
    event.preventDefault();
    event.stopPropagation();
    event.target.classList.add(styles.DragOver);
  };

  const dragLeave = (event) => {
    event.preventDefault();
    event.stopPropagation();
    event.target.classList.remove(styles.DragOver);
  };

  const drop = (event) => {
    event.preventDefault();
    event.stopPropagation();
    event.target.classList.remove(styles.DragOver);

    const files = event.dataTransfer?.files;
    const file = files?.[0];

    if (file) {
      rest.onChange(name, file);
    }
  };

  const onSelect = () => {
    inputRef.current?.click?.();
  };

  const onChange = (event) => {
    const files = event.target?.files;
    const file = files?.[0];

    if (file) {
      rest.onChange(name, file);
    }
  };

  return (
    <Fragment>
      <div
        ref={draggableRef}
        draggable
        className={classNames(styles.Draggable, 'p-5')}
        onClick={onSelect}
      >
        Arraste o haga clic para seleccionar el archivo
      </div>
      <FormControl
        ref={inputRef}
        type="file"
        hidden
        isInvalid={isInvalid}
        onChange={onChange}
      />
    </Fragment>
  );
};

File.propTypes = {
  name: PropTypes.string,
  isInvalid: PropTypes.bool,
  onChange: PropTypes.func
};

export default File;
