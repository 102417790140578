import { Link } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Folder } from 'react-bootstrap-icons';
import useFileAnios from '../../../hooks/useFilesAnios';
import Table from '../../../components/UI/Table';

const Anios = () => {
  const {
    years,
    isLoading,
    getLinkPath,
    getLinkState
  } = useFileAnios();

  return (
    <Row>
      <Col>
        <div className="bg-white">
          <Table>
            <thead>
              <tr>
                <th>Año</th>
                <th>Total de archivos</th>
              </tr>
            </thead>
            <tbody>
              {isLoading ? (
                <tr>
                  <td
                    colSpan={2}
                    className="text-center"
                  >
                    Cargando...
                  </td>
                </tr>
              ) : null}
              {years.map((year) => (
                <tr key={year.id}>
                  <td>
                    <Link
                      to={getLinkPath(year.id)}
                      state={getLinkState(year)}
                    >
                      <Folder /> {year.year}
                    </Link>
                  </td>
                  <td>{year.files_count}</td>
                </tr>
              ))}
              {(!isLoading && !years.length) ? (
                <tr>
                  <td
                    colSpan={2}
                    className="text-center"
                  >
                    No se han encontrado resultados.
                  </td>
                </tr>
              ) : null}
            </tbody>
          </Table>
        </div>
      </Col>
    </Row>
  );
};

export default Anios;
