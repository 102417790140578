import { useState, useEffect, useReducer, useContext } from 'react';
import FilesContext from '../contexts/FilesContext';
import { initialState, reducer } from '../store/local/reducers/files-clientes';
import * as actions from '../store/local/actions/files-clientes';

export default function useFilesClientes() {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [page, setPage] = useState(1);

  const {
    search,
    file,
    isUploadedSuccessfully,
    onSearch
  } = useContext(FilesContext);

  useEffect(() => {
    return () => {
      onSearch('');
    };
  }, []);

  useEffect(() => {
    getClientes({ page, search });
  }, [page, search]);

  useEffect(() => {
    addToFilesCount();
  }, [isUploadedSuccessfully]);

  const getClientes = (params = {}) => {
    actions.getClientes(params)(dispatch);
  };

  const getLinkState = (cliente) => {
    return {
      menu: [{
        id: cliente.id,
        title: cliente.full_name,
        path: `${cliente.id}/anios`
      }],
      userId: cliente.id
    };
  };

  const addToFilesCount = () => {
    if (isUploadedSuccessfully) {
      actions.addToFilesCount(file.user_id, 1)(dispatch);
    }
  };

  const onNextOrPrevClientes = (page) => {
    setPage(page);
  };

  return {
    clientes: state.clientes,
    pagination: state.pagination,
    isLoading: state.isLoading,
    getLinkState,
    onNextOrPrevClientes
  };
}
